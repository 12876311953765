import {
  AccordionGroup,
  AssetTransaction,
  ExecutionReport,
  MarketExecutionReport,
  MarketOrder,
  MarketQuote,
  MixpanelEventSource,
  MixpanelSourceProvider,
  VStack,
  type BlotterTableFilter,
  type Column,
} from '@talos/kyoko';
import { CustomerExecutionReportsBlotterTable } from 'containers/Blotters/CustomerExecutionReports/CustomerExecutionReportsBlotter';
import { CustomerQuotesBlotterTable } from 'containers/Blotters/CustomerQuotes/CustomerQuotesBlotter';
import { ExecutionReportsBlotterTable } from 'containers/Blotters/ExecutionReports';
import { MarketExecutionReportsBlotterTable } from 'containers/Blotters/MarketExecutionReports';
import { MarketOrdersBlotterTable } from 'containers/Blotters/MarketOrders';
import { MarketQuotesBlotterTable } from 'containers/Blotters/MarketQuotes/MarketQuotesBlotter';
import { FilteredOrders } from 'containers/Blotters/Orders/FilteredOrders';
import { QuotesBlotterTable } from 'containers/Blotters/Quotes/QuotesBlotter';
import { FilteredTrades } from 'containers/Blotters/Trades/FilteredTrades';
import { DEFAULT_TRADE_COLUMNS } from 'containers/Blotters/Trades/columns';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { isEmpty } from 'lodash';
import { useMemo, type FC } from 'react';
import { AssetTransactionsBlotterTable } from '../../Blotters/AssetTransactions/AssetTransactionsBlotter';
import { BuyingPowerBlotterTable } from '../../Blotters/BuyingPower/BuyingPowerBlotter';
import { CustomerOrdersBlotterTable } from '../../Blotters/CustomerOrders/CustomerOrdersBlotter';
import { CustomerTradesBlotterTable } from '../../Blotters/CustomerTrades';
import { MarketTradesBlotterTable } from '../../Blotters/MarketTrades';
import { entityByRequestName, type BlotterTableEntity } from './types';

export interface MonitoringBlotterProps {
  blotterID: string;
  tabLabel?: string;
  onDoubleClickRow(data: BlotterTableEntity): void;
  defaultFilter: BlotterTableFilter;
  initialIsOpen?: boolean;
  selectedEntity: keyof typeof entityByRequestName;
  /** filter and columns are current state to be cloned to new tab */
  onCloneTab: (filter: BlotterTableFilter, columns: Column[]) => void;
  defaultColumns?: (string | Column)[];
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
  source?: 'monitoring' | 'dealer-monitoring';
}

interface GenericBlotterProps {
  blotterID: string;
  tabLabel?: string;
  defaultFilter: BlotterTableFilter;
  defaultColumns?: any[];
  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: Column[]) => void;
  initialIsOpen?: boolean;
  showAPIOrdersToggle?: boolean;
  source?: 'monitoring' | 'dealer-monitoring';
}

const BlotterMixpanelEventSourceLookup = {
  ExecutionReport: MixpanelEventSource.MonitoringExecutionReportsBlotter,
  Order: MixpanelEventSource.MonitoringOrdersBlotter,
  Quote: MixpanelEventSource.MonitoringQuotesBlotter,
  Trade: MixpanelEventSource.MonitoringTradesBlotter,
  CustomerExecutionReport: MixpanelEventSource.MonitoringCustomerExecutionReportsBlotter,
  CustomerOrder: MixpanelEventSource.MonitoringCustomerOrdersBlotter,
  CustomerQuote: MixpanelEventSource.MonitoringCustomerQuotesBlotter,
  CustomerTrade: MixpanelEventSource.MonitoringCustomerTradesBlotter,
  MarketExecutionReport: MixpanelEventSource.MonitoringMarketExecutionReportsBlotter,
  MarketOrder: MixpanelEventSource.MonitoringMarketOrdersBlotter,
  MarketQuote: MixpanelEventSource.MonitoringMarketQuotesBlotter,
  MarketTrade: MixpanelEventSource.MonitoringMarketTradesBlotter,
  AssetTransaction: MixpanelEventSource.MonitoringMarketCashEventsBlotter,
  BuyingPower: MixpanelEventSource.BuyingPowerBlotter,
} as const satisfies Record<keyof typeof entityByRequestName, MixpanelEventSource>;

export function MonitoringBlotter(props: MonitoringBlotterProps) {
  const selectedEntity = props.selectedEntity ?? Object.keys(entityByRequestName)[0];

  const SelectedBlotter: FC<GenericBlotterProps> = useMemo<FC<GenericBlotterProps>>((): any => {
    switch (selectedEntity) {
      case 'Order':
        return FilteredOrders;
      case 'Trade':
        return FilteredTrades;
      case 'ExecutionReport':
        return ExecutionReportsBlotterTable;
      case 'MarketOrder':
        return MarketOrdersBlotterTable;
      case 'MarketExecutionReport':
        return MarketExecutionReportsBlotterTable;
      case 'Quote':
        return QuotesBlotterTable;
      case 'MarketQuote':
        return MarketQuotesBlotterTable;
      case 'MarketTrade':
        return MarketTradesBlotterTable;
      case 'AssetTransaction':
        return AssetTransactionsBlotterTable;
      case 'CustomerOrder':
        return CustomerOrdersBlotterTable;
      case 'CustomerQuote':
        return CustomerQuotesBlotterTable;
      case 'CustomerTrade':
        return CustomerTradesBlotterTable;
      case 'CustomerExecutionReport':
        return CustomerExecutionReportsBlotterTable;
      case 'BuyingPower':
        return BuyingPowerBlotterTable;
      default: {
        const _exhaustiveCheck: never = selectedEntity;
      }
    }
  }, [selectedEntity]);

  const mixpanelEventSource: MixpanelEventSource = useMemo<MixpanelEventSource>(() => {
    return BlotterMixpanelEventSourceLookup[selectedEntity];
  }, [selectedEntity]);

  const defaultColumns: GenericBlotterProps['defaultColumns'] = useMemo(() => {
    if (!isEmpty(props.defaultColumns)) {
      return props.defaultColumns;
    }
    switch (selectedEntity) {
      case 'Trade':
        return DEFAULT_TRADE_COLUMNS;
      case 'ExecutionReport':
        return ExecutionReport.defaultColumns;
      case 'MarketOrder':
        return MarketOrder.defaultColumns;
      case 'MarketExecutionReport':
        return MarketExecutionReport.defaultColumns;
      case 'MarketQuote':
        return MarketQuote.defaultColumns;
      case 'AssetTransaction':
        return AssetTransaction.defaultColumns;
      case 'Quote':
      case 'Order':
      case 'CustomerQuote':
      case 'CustomerExecutionReport':
      case 'CustomerOrder':
      case 'CustomerTrade':
      case 'MarketTrade':
      case 'BuyingPower':
        return []; // use the defaults defined by the blotter
      default:
        throw new Error(`Unknown entity ${selectedEntity}`);
    }
  }, [props.defaultColumns, selectedEntity]) as Column[];

  return (
    <VStack w="100%" h="100%" alignItems="stretch" justifyContent="flex-start">
      <MixpanelSourceProvider value={mixpanelEventSource}>
        <AccordionGroup>
          <SelectedBlotter {...props} defaultColumns={defaultColumns} showAPIOrdersToggle={true} />
        </AccordionGroup>
      </MixpanelSourceProvider>
    </VStack>
  );
}
