import type { WritableDraft } from 'immer/dist/types/types-external';

import {
  AllocationValueTypeEnum,
  FieldValidationLevel,
  PricingMode,
  SideEnum,
  format,
  isBasis,
  isCalendarSpread,
  isOption,
  toBig,
  toBigWithDefault,
  type DateField,
  type Field,
  type FieldValidationResult,
  type FieldValidationRule,
  type NumericField,
} from '@talos/kyoko';
import Big from 'big.js';
import type { LegParams } from 'providers/OMSContext.types';
import { validateQuantity } from './rules';
import type { OrderFormState, OrderState } from './types';

export const allocationTotalValidation: FieldValidationRule<NumericField, WritableDraft<OrderFormState>> = (
  field,
  context
) => {
  const valueType = context?.allocationValueTypeField.value;
  const allocations = context?.allocations.map(pair => pair.valueField.bigValue) || [];
  const quantity = new Big(context?.quantityField.value || 0);

  const sum = allocations.reduce((acc: Big, value: Big | undefined) => {
    if (value) {
      return acc.add(value);
    }
    return acc;
  }, new Big(0));

  if (!field.hasValue || field.value === '0') {
    return {
      message: `${field.name} cannot be 0`,
      level: FieldValidationLevel.Error,
    };
  }

  if (valueType === AllocationValueTypeEnum.Percentage) {
    if (sum.gt(1)) {
      return {
        message: 'Total cannot exceed 100%',
        level: FieldValidationLevel.Error,
      };
    }
    if (sum.lt(1)) {
      return {
        message: 'Total must add up to 100%',
        level: FieldValidationLevel.Error,
      };
    }
  } else {
    if (sum.gt(quantity)) {
      return {
        message: 'Total cannot exceed Quantity',
        level: FieldValidationLevel.Error,
      };
    }
    if (sum.lt(quantity)) {
      return {
        message: 'Total must add up to Quantity',
        level: FieldValidationLevel.Error,
      };
    }
  }

  return null;
};

export const quantityValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (field, context) => {
  if (!context || (!field.isRequired && !field.value)) {
    return null;
  }
  const { form, referenceData } = context;
  const security = form?.symbolField.value;
  if (!security) {
    return null;
  }

  const quantity = new Big(field.value || 0);
  const orderCurrency = form.orderCurrencyField.value || '';
  return validateQuantity(security, quantity, orderCurrency, referenceData.currencies.currenciesBySymbol);
};

export const notInPastValidation: FieldValidationRule<DateField, WritableDraft<OrderState>> = (field, context) => {
  if (!context || !field.hasValue) {
    return null;
  }

  const now = new Date();
  const time = field.value!;

  if (now.valueOf() - new Date(time).valueOf() > 0) {
    return {
      message: `Time cannot be in the past`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

export const endTimeValidation: FieldValidationRule<DateField, WritableDraft<OrderState>> = (field, context) => {
  if (!context || !context.form.parameters.StartTime.hasValue || !context.form.parameters.EndTime.hasValue) {
    return null;
  }

  const startTime = context.form.parameters.StartTime.value;
  const endTime = context.form.parameters.EndTime.value;

  if (new Date(startTime).valueOf() - new Date(endTime).valueOf() > 0) {
    return {
      message: `End Time cannot be before Start Time`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

export const optionPriceRangeValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (
  field,
  context
) => {
  if (!context || !context.form.priceField.hasValue || !context.form.priceField.isRequired) {
    return null;
  }
  const { form } = context;
  const security = form.symbolField.value;
  const currency = form.orderCurrencyField.value;
  const priceMode = form.priceModeField.value;
  const side = form.sideField.value;
  const minOrderPrice = toBig(context.marketDataStatistics?.MinOrderPrice);
  const maxOrderPrice = toBig(context.marketDataStatistics?.MaxOrderPrice);

  if (!security || !isOption(security) || !minOrderPrice || !maxOrderPrice || priceMode !== PricingMode.Default) {
    return null;
  }
  const price = toBig(context.form.priceField.value);

  if (side === SideEnum.Buy && price?.gte(maxOrderPrice)) {
    return {
      message: `Price must be lower than ${format(maxOrderPrice, {
        spec: security.DefaultPriceIncrement,
      })} ${currency}`,
      level: FieldValidationLevel.Error,
    };
  } else if (side === SideEnum.Sell && price?.lte(minOrderPrice)) {
    return {
      message: `Price must be higher than ${format(minOrderPrice, {
        spec: security.DefaultPriceIncrement,
      })} ${currency}`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

// Confirmed with Zheng that the 0 rate is valid on Delta-1, Option Combos and Calendar Spreads (native)
// Spot, Perp, Futures, Options, Syntheitic Cross - rate must be greater than 0.
// Delta-1, Option Combos, Calendar Spreads (Basis) - rate can be negative, 0 or positive.
export const priceValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (field, context) => {
  if (!context || !context.form.priceField.hasValue || !context.form.priceField.isRequired) {
    return null;
  }
  const { form } = context;
  const security = form.symbolField.value;

  if (!security || isBasis(security) || isCalendarSpread(security)) {
    return null;
  }

  const price = toBig(context.form.priceField.value);

  if (price?.lte(0)) {
    return {
      message: `Price must be greater than 0`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

export const legParamMarkets: FieldValidationRule<Field<LegParams[]>, WritableDraft<OrderState>> = field => {
  let res: FieldValidationResult | null = null;
  field.value?.forEach(leg => {
    if (field.isRequired && leg.Markets.length === 0) {
      res = { message: `Markets are required for all legs`, level: FieldValidationLevel.Error };
    }
  });
  return res;
};

export const legParamInitiating: FieldValidationRule<Field<LegParams[]>, WritableDraft<OrderState>> = field => {
  const intitiatingLeg = field.value?.find(leg => leg.Initiating);
  if (field.isRequired && intitiatingLeg === undefined) {
    return { message: 'At least 1 leg must Initiate', level: FieldValidationLevel.Error };
  }
  return null;
};

export const clipSizeValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (field, context) => {
  if (!context || !context.form.parameters.ClipSize.hasValue || !context.form.quantityField.hasValue) {
    return null;
  }

  const quantity = toBig(context.form.quantityField.value);
  if (quantity?.lt(context.form.parameters.ClipSize.value)) {
    return {
      message: `Clip Size is greater than quantity`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

export const greaterThanCumQtyValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (
  field,
  context
) => {
  if (!context || !context.orderBeingModified) {
    return null;
  }

  // Ensure that the quantity is not less than the modified order's CumQty
  if (field.value && toBigWithDefault(field.value, 0).gte(toBigWithDefault(context.orderBeingModified.CumQty, 0))) {
    return null;
  } else {
    return {
      message: `Quantity cannot be less than already executed quantity: ${context.orderBeingModified.CumQty}`,
      level: FieldValidationLevel.Error,
    };
  }
};
