import { useDefaultColumns, type Column, type ColumnDef, type ISubaccount, type Paths, type Tree } from '@talos/kyoko';
import { useRollupTreeRef } from 'hooks';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo } from 'react';
import type { PortfolioBreakdownBlotterGridItem } from '../PortfolioPerformanceBlotter/types';
import { getPnLColumn } from '../utils';

function getVisibleColumns(): Array<keyof ReturnType<typeof usePortfolioBreakdownColumnDefs>['mapping']> {
  return ['pmsWarnings', 'position', 'positionHome', 'price', 'deltaExp', 'pnl24H'];
}

export const usePortfolioBreakdownColumns = (): {
  defaultColumns: Column[];
} => {
  const { homeCurrency } = useDisplaySettings();
  const rollupTreeRef = useRollupTreeRef();
  const visibleColumns = useMemo(() => {
    return getVisibleColumns();
  }, []);

  const { colDefsMap } = usePortfolioBreakdownColumnDefs(rollupTreeRef, homeCurrency);
  const defaultColumns = useDefaultColumns(visibleColumns, colDefsMap);
  return {
    defaultColumns,
  };
};

function usePortfolioBreakdownColumnDefs(
  rollupTreeRef: React.MutableRefObject<Tree<ISubaccount>>,
  homeCurrency: string
) {
  return useMemo(() => {
    const mapping = {
      pmsWarnings: {
        field: 'PMSWarningColumnValue',
        type: 'pmsWarnings',
        titleIntlKey: 'warning',
        aggregate: true,
        pinned: 'left',
      },
      subAccount: {
        id: 'SubAccount',
        field: 'SubAccount',
        type: 'subAccountName',
        titleIntlKey: 'subAccount',
        sortable: true,
        hide: true,
        params: {
          rollupTree: rollupTreeRef,
        },
      },
      assetField: {
        id: 'Asset',
        field: 'Asset',
        type: 'asset',
        titleIntlKey: 'asset',
        pinned: 'left',
        sortable: true,
        width: 150,
        hide: true,
        title: 'Asset',
        params: {
          assetTypeField: 'AssetType' satisfies Paths<PortfolioBreakdownBlotterGridItem>,
          colorful: true,
        },
        enableRowGroup: true,
      },
      weight: {
        id: 'Weight',
        field: 'Equivalent.Weight',
        title: 'Weight (%)',
        width: 80,
        type: 'percent',
        sortable: true,
      },
      position: {
        id: 'Position',
        field: 'Position',
        title: 'Position Qty',
        type: 'size',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'Asset' satisfies Paths<PortfolioBreakdownBlotterGridItem>,
          trimTrailingZeroes: true,
        },
        sortable: true,
        description: 'Position normalized in asset currency.',
      },
      positionHome: {
        type: 'size',
        field: 'Equivalent.Position',
        title: `Position (${homeCurrency})`,
        params: {
          currencyField: 'Equivalent.Currency' satisfies Paths<PortfolioBreakdownBlotterGridItem>,
          highlightNegative: true,
        },
        sortable: true,
        aggregate: true,
        description: 'Position normalized in home currency, by using mark and underlying prices.',
      },
      price: {
        field: 'MarkPrice',
        type: 'price',
        titleIntlKey: 'markPrice',
        width: 150,
        params: {
          quoteCurrencyField: 'MarkPriceCurrency' satisfies Paths<PortfolioBreakdownBlotterGridItem>,
        },
        description: 'Most recent mark price for the instrument provided by the venue.',
      },
      deltaExp: {
        field: 'Equivalent.CcyDeltaExposure.Net',
        type: 'size',
        params: { currencyField: 'Equivalent.Currency' satisfies Paths<PortfolioBreakdownBlotterGridItem> },
        sortable: true,
        aggregate: true,
        title: `Delta Exp (${homeCurrency})`,
        width: 150,
        description: 'Net Delta Exposure normalized in home currency',
      },
      pnl24H: getPnLColumn<PortfolioBreakdownBlotterGridItem>({
        title: '24H PnL',
        field: 'PnlLookbacks.H24.Equivalent.PnLDelta' satisfies Paths<PortfolioBreakdownBlotterGridItem>,
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 24 hours to the current price mark.',
      }),
    } as const satisfies Record<string, ColumnDef<PortfolioBreakdownBlotterGridItem>>;

    const colDefsMap = Object.entries(mapping).reduce((result, [key, value]) => {
      result.set(key, value);
      return result;
    }, new Map<string, Column>());

    return {
      /** This mapping (as const) is being used for constant typing downstream - See {@link getVisibleColumns} */
      mapping,
      colDefsMap,
    };
  }, [homeCurrency, rollupTreeRef]);
}
