import {
  Divider,
  Flex,
  HStack,
  Icon,
  IconName,
  IndicatorBadge,
  Meter,
  MultilegReportingTypeEnum,
  SmartSummary,
  Text,
  toBigWithDefault,
  useSecurity,
  type IOMSExecutionReport4203LegSummary,
  type MeterProps,
  type Order,
} from '@talos/kyoko';
import Big from 'big.js';
import { Fragment } from 'react';
import { isMultiLegSecurity } from 'utils/security';

export const MultilegTooltip = ({ meterProps, data }: { meterProps: MeterProps; data: Order }) => {
  const { LegSummary, OrderQty } = data;

  if (!LegSummary) {
    return null;
  }

  return (
    <Flex flexDirection="column" gap="spacingComfortable">
      {LegSummary.filter(
        // Only include the legs and any unified symbols
        leg => leg.MultilegReportingType === MultilegReportingTypeEnum.Leg || leg.Symbol !== data.Symbol
      ).map((leg, index) => (
        <Fragment key={leg.Symbol}>
          {index !== 0 && <Divider />}
          <LegDetail legSummary={leg} color={meterProps.color} orderQty={OrderQty} />
        </Fragment>
      ))}
    </Flex>
  );
};

const LegDetail = ({
  legSummary,
  color,
  orderQty,
}: {
  legSummary: IOMSExecutionReport4203LegSummary;
  color?: string;
  orderQty: Order['OrderQty'];
}) => {
  const value = Big(legSummary.ParentCumQty || 0)
    .div(orderQty || '1')
    .toNumber();

  const symbol = legSummary.Symbol;
  const security = useSecurity(symbol);

  if (!security || isMultiLegSecurity(security)) {
    return null;
  }

  return (
    <Flex flexDirection="column" gap="spacingSmall" minWidth="250px">
      <HStack justifyContent="space-between" gap="spacingDefault">
        <Text transform="uppercase" fontSize="fontSizeXs" whiteSpace="nowrap">
          <IndicatorBadge w={18} h={18}>
            {legSummary.MultilegReportingType === MultilegReportingTypeEnum.Parent ? (
              <Icon icon={IconName.Database} rotate={90} />
            ) : (
              legSummary.LegIndex + 1
            )}
          </IndicatorBadge>{' '}
          {security.DisplaySymbol}
        </Text>
        <SmartSummary
          entity={{
            Symbol: symbol,
            Currency: security.PositionCurrency || '',
            AvgPx: legSummary.AvgPx,
            OrderQty: toBigWithDefault(legSummary.CumQty, 0)
              .mul(security.NotionalMultiplier || 1)
              .toFixed(),
            AmountCurrency: security.QuoteCurrency,
          }}
          priceField="AvgPx"
          qtyField="OrderQty"
          showSide={false}
          type="order"
          fontSize="fontSizeSmall"
        />
      </HStack>
      <Meter value={value} color={color} showInitialAnimation={false} />
    </Flex>
  );
};
