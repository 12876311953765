import {
  type AggregationWithAccounts,
  Flex,
  FormGroup,
  type FormGroupProps,
  Icon,
  IconName,
  MarketSelectionsList,
  SearchSelect,
  type SearchSelectProps,
  type SearchSelectRef,
} from '@talos/kyoko';
import { useMemo } from 'react';

export function AggregationsSelector(
  props: SearchSelectProps<AggregationWithAccounts> & { ref?: React.ForwardedRef<SearchSelectRef> } & {
    showMarketSelections?: boolean;
    marketSelectionsHeight?: string;
    /** If provided, will wrap the selector within a form group. */
    formGroupProps?: FormGroupProps;
  }
) {
  const { selection, showMarketSelections = true, marketSelectionsHeight, formGroupProps } = props;

  const selections = useMemo(
    () => (selection?.Accounts ? Array.from(selection?.Accounts.keys()) : []) satisfies string[],
    [selection]
  );

  const select = <SearchSelect {...props} prefix={<Icon icon={IconName.CommandCmd} color="purple.default" />} />;

  if (formGroupProps) {
    return (
      <>
        <FormGroup {...formGroupProps}>{select}</FormGroup>
        {showMarketSelections && <MarketSelectionsList h={marketSelectionsHeight} selections={selections} />}
      </>
    );
  }

  return (
    <>
      <Flex gap="spacingDefault" flexDirection="column">
        {select}
        {showMarketSelections && <MarketSelectionsList h={marketSelectionsHeight} selections={selections} />}
      </Flex>
    </>
  );
}
