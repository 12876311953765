import { Button, FormControlSizes, HStack, Icon, IconName, Text, getIconSize } from '@talos/kyoko';
import { useCallback, useContext } from 'react';
import type { ContextGuideID } from '../../providers';
import { ContextGuideContext } from '../../providers';

export function GuideButton({ guideID }: { guideID: ContextGuideID }) {
  const { setGuide, getGuide } = useContext(ContextGuideContext);
  const toggleHelpGuide = useCallback(() => {
    const newValue = !getGuide(guideID);
    setGuide(guideID, newValue);
  }, [getGuide, setGuide, guideID]);

  const isGuideEnabled = getGuide(guideID);

  return (
    <Button
      size={FormControlSizes.Small}
      onClick={toggleHelpGuide}
      ghost={!isGuideEnabled}
      data-testid="toggle-guide-button"
    >
      <HStack gap="spacingTiny">
        <Icon
          size={getIconSize(FormControlSizes.Small)}
          icon={IconName.LightBulb}
          color={isGuideEnabled ? 'colors.yellow.default' : 'colorTextSubtle'}
        />
        <Text color={isGuideEnabled ? 'colorTextImportant' : 'colorTextSubtle'}>
          {isGuideEnabled ? 'Hide' : 'Show'} Guide
        </Text>
      </HStack>
    </Button>
  );
}
