import {
  Alert,
  Box,
  Button,
  ButtonVariants,
  Divider,
  type ErrorResponse,
  FormGroup,
  getErrorMessage,
  Grid,
  IconName,
  LoaderSizes,
  LoaderTalos,
  MixpanelEvent,
  Modal,
  NotificationVariants,
  NumberInput,
  runValidation,
  Toggle,
  type UseDisclosureReturn,
  useDynamicCallback,
  useGlobalToasts,
  useMixpanel,
  validatePrecision,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type BaseSchema, number, object } from 'yup';
import { ModalHeader } from '../../../../../../../packages/kyoko/src/components/Modal/ModalHeader';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../../providers';
import { useEquityMarginRatioLimitContext } from '../../../../providers/EquityMarginRatioLimitProvider';
import { MIN_EQUITY_MARGIN_RATIO_INCREMENT } from '../types';
import { type EquityMarginRatioLimitRequest, useEquityMarginRatioLimits } from '../useEquityMarginRatioLimits';

interface EquityMarginRatioDialogProps {
  dialog: UseDisclosureReturn;
}

interface EquityMarginRatioForm {
  equityMarginRatioEnabled: boolean;
  equityMarginRatio: string;
}

const schema: Partial<Record<keyof EquityMarginRatioForm, BaseSchema>> = {
  equityMarginRatio: number()
    .required('Please enter Equity Margin Ratio')
    .typeError('Please enter Equity Margin Ratio')
    .moreThan(0, 'Equity Margin Ratio must be be greater than 0')
    .test('precision', `Min increment is ${MIN_EQUITY_MARGIN_RATIO_INCREMENT}`, q =>
      validatePrecision(MIN_EQUITY_MARGIN_RATIO_INCREMENT, q)
    ),
};

const validate = function (form: Partial<EquityMarginRatioForm>) {
  return runValidation(object().shape(schema), form);
};

export function EquityMarginRatioDialog(props: EquityMarginRatioDialogProps) {
  const mixpanel = useMixpanel();
  const { upsert: upsertConfig } = useOrgConfiguration();
  const [form, setForm] = useState<Partial<EquityMarginRatioForm>>({});
  const initialForm = useRef<Partial<EquityMarginRatioForm>>({});
  const [errors, setErrors] = useState<Partial<Record<keyof EquityMarginRatioForm, string>>>(validate(form));
  const [loading, setIsLoading] = useState(false);
  const { globalEquityMarginRatioLimit } = useEquityMarginRatioLimitContext();
  const { add: addToast } = useGlobalToasts();
  const { dialog } = props;
  const { isOpen } = dialog;

  const { isEquityMarginRatioLimitCheckEnabled, createEquityMarginRatioLimit, updateEquityMarginRatioLimit } =
    useEquityMarginRatioLimits();

  const isDirty = useMemo(() => !isEqual(initialForm.current, form), [initialForm, form]);

  const updateForm = useCallback((update: Partial<EquityMarginRatioForm>) => {
    setForm(prev => ({ ...prev, ...update }));
  }, []);

  useEffect(() => {
    if (isOpen) {
      initialForm.current = {
        equityMarginRatioEnabled: isEquityMarginRatioLimitCheckEnabled,
        equityMarginRatio: globalEquityMarginRatioLimit?.Ratio,
      };
      updateForm(initialForm.current);
    }
  }, [isOpen, isEquityMarginRatioLimitCheckEnabled, initialForm, globalEquityMarginRatioLimit, updateForm]);

  useEffect(() => {
    setErrors(validate(form));
  }, [form]);

  const handleClose = useCallback(() => {
    dialog.close();
  }, [dialog]);

  const handleSave = useDynamicCallback(() => {
    setIsLoading(true);
    // Update org config only when switch has changed compared to current config
    if (form.equityMarginRatioEnabled !== isEquityMarginRatioLimitCheckEnabled) {
      upsertConfig({
        Key: OrgConfigurationKey.DisableEquityMarginRatioLimitCheck,
        Value: form.equityMarginRatioEnabled ? '0' : '1',
      });
      mixpanel.track(
        form.equityMarginRatioEnabled
          ? MixpanelEvent.EnableEquityMarginRatioLimit
          : MixpanelEvent.DisableEquityMarginRatioLimit
      );
    }
    const request: Promise<EquityMarginRatioLimitRequest> = globalEquityMarginRatioLimit
      ? updateEquityMarginRatioLimit(globalEquityMarginRatioLimit.LimitID, {
          Ratio: form.equityMarginRatio ?? '',
        })
      : createEquityMarginRatioLimit({
          Ratio: form.equityMarginRatio ?? '',
        });
    request
      .then(() => {
        addToast({
          text: 'Default Equity Margin Ratio updated.',
          variant: NotificationVariants.Positive,
        });
        handleClose();
      })
      .catch((e: ErrorResponse) => {
        addToast({
          text: getErrorMessage(e),
          variant: NotificationVariants.Negative,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return (
    <Modal {...dialog} width={440} data-testid="default-settings-dialog">
      <ModalHeader
        icon={IconName.Cog}
        title="Trading Controls"
        subtitle="Default Settings"
        onCloseClicked={handleClose}
      />
      <Box p="spacingMedium">
        <Alert showIcon={true} dismissable={false}>
          Note that settings below are used as defaults.
        </Alert>

        <FormGroup
          tooltip="The Equity Margin Ratio Limit allows an organization to reduce risk associated with derivatives trading by enforcing their leverage limits across different trading platforms. The specified default EMR Limit can be overwritten at a market account level."
          label="Equity Margin Ratio"
          inline
          alignItems="center"
          justifyContent="space-between"
          mt="spacingMedium"
          mb="spacingDefault"
        >
          <Toggle
            data-testid="equity-margin-ratio-toggle"
            disabled={loading}
            onChange={(checked: boolean) => updateForm({ equityMarginRatioEnabled: checked })}
            checked={form.equityMarginRatioEnabled || false}
          />
        </FormGroup>

        {form.equityMarginRatioEnabled && (
          <FormGroup error={errors.equityMarginRatio}>
            <NumberInput
              data-testid="equity-margin-ratio-input"
              disabled={loading}
              value={form.equityMarginRatio || ''}
              minIncrement={MIN_EQUITY_MARGIN_RATIO_INCREMENT}
              onChange={value => updateForm({ equityMarginRatio: value })}
            />
          </FormGroup>
        )}
      </Box>

      <Divider />
      <Grid columns="1fr 1fr" rows={1} gap="spacingDefault" p="spacingMedium">
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={loading || !isDirty || Object.keys(errors).length > 0}
          variant={ButtonVariants.Positive}
          onClick={handleSave}
        >
          {loading && <LoaderTalos size={LoaderSizes.XXS} />}
          {!loading && <>Save</>}
        </Button>
      </Grid>
    </Modal>
  );
}
