import {
  Button,
  ButtonVariants,
  Flex,
  FormControlSizes,
  HStack,
  HedgeControlStatusEnum,
  Icon,
  IconButton,
  IconName,
  IndicatorBadge,
  IndicatorBadgeVariants,
  MixpanelEvent,
  ModeEnum,
  Popover,
  PreviewWrapperBox,
  Spinner,
  Tab,
  TabList,
  TabSize,
  Tabs,
  Text,
  useDisclosureWithContext,
  useMixpanel,
  useObservableValue,
  usePopoverState,
} from '@talos/kyoko';

import { useCallback, useState } from 'react';
import { map } from 'rxjs';
import { useHedgePositionStatus } from '../../providers/HedgePositionStatusProvider';
import { ConfirmModePreview } from './ConfirmModePreview';
import { SmallHedgePositionStatusBlotterTable } from './SmallHedgePositionStatusBlotterTable';
import { useNavigateToHedgeRulesSetup } from './useNavigateToHedgeRulesSetup';

/**
 * Button which shows the current status of the hedger and allows the user to activate or deactivate the hedger.
 */
export function AutoHedgingButton() {
  const mixpanel = useMixpanel();
  const previewView = useDisclosureWithContext<ModeEnum>({
    onClose: () => {
      mixpanel.track(MixpanelEvent.ExitConfirmGlobalAutohedgingCommand);
    },
  });
  const popover = usePopoverState({
    placement: 'bottom-end',
    closeOnClickOutside: true,
    noPaddingAndBorder: true,
    onOpen: () => {
      mixpanel.track(MixpanelEvent.ClickHeaderAutoHedgingButton);
    },
    onClose: () => {
      previewView.close();
    },
  });
  const { close: handleClose, open: handleClickOpen } = popover;

  const { hedgePositionStatusObs, globalHedgePositionStatusObs } = useHedgePositionStatus();

  const { errorCount, hedgingCount } = useObservableValue(
    () =>
      hedgePositionStatusObs.pipe(
        map(hedgePositionStatus => {
          return {
            errorCount:
              Array.from(hedgePositionStatus.values()).filter(
                h => h.HedgeControlStatus === HedgeControlStatusEnum.Error
              ).length ?? 0,
            hedgingCount:
              Array.from(hedgePositionStatus.values()).filter(
                h => h.HedgeControlStatus === HedgeControlStatusEnum.Hedging
              ).length ?? 0,
          };
        })
      ),
    [hedgePositionStatusObs],
    { errorCount: 0, hedgingCount: 0 }
  );

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const navigateToHedgeRules = useNavigateToHedgeRulesSetup();
  const handleClickSettingsCog = useCallback(() => {
    navigateToHedgeRules();
    handleClose();
  }, [handleClose, navigateToHedgeRules]);

  const globalHedgeRule = useObservableValue(
    () =>
      globalHedgePositionStatusObs.pipe(
        map(json => {
          return json.data.at(0);
        })
      ),
    [globalHedgePositionStatusObs]
  );

  const hedgeRulesLoading = !globalHedgeRule;
  const hedgerEnabled = Boolean(
    globalHedgeRule && globalHedgeRule?.HedgeControlStatus !== HedgeControlStatusEnum.Disabled
  );
  const modeToBeChangedTo = hedgerEnabled ? ModeEnum.Disabled : ModeEnum.Enabled;

  return (
    <Popover {...popover}>
      <HedgingButton
        errorCount={errorCount}
        hedgeRulesLoading={hedgeRulesLoading}
        hedgerEnabled={hedgerEnabled}
        onClick={handleClickOpen}
      />

      <PreviewWrapperBox w="700px">
        <ConfirmModePreview key={previewView.openId} previewView={previewView} />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap="spacingSmall"
          background="colors.gray.050"
          px="spacingDefault"
          py="spacingSmall"
        >
          <Flex alignItems="inherit" gap="inherit">
            <Text>Position Autohedging</Text>
            <Button
              size={FormControlSizes.Tiny}
              variant={ButtonVariants.Default}
              endIcon={IconName.ExternalLink}
              onClick={handleClickSettingsCog}
            >
              Settings
            </Button>
          </Flex>

          <IconButton ghost icon={IconName.Close} size={FormControlSizes.Small} onClick={handleClose} />
        </Flex>
        <Flex w="100%" flexDirection="column" gap="0" background="colors.gray.040">
          <Flex>
            <Tabs size={TabSize.Large} selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
              <TabList>
                <Tab label="All" />
                <Tab
                  label={StatusForIdx[1]}
                  suffix={
                    <IndicatorBadge ml="spacingSmall" children={errorCount} variant={IndicatorBadgeVariants.Negative} />
                  }
                />
                <Tab
                  label={StatusForIdx[2]}
                  suffix={
                    <IndicatorBadge
                      ml="spacingSmall"
                      children={hedgingCount}
                      variant={IndicatorBadgeVariants.Positive}
                    />
                  }
                />
              </TabList>
            </Tabs>

            <Flex h="fit-content" p="spacingSmall" py="spacingDefault" justifyContent="flex-end" w="100%">
              <Button
                data-testid="open-command-preview-button"
                size={FormControlSizes.Small}
                variant={
                  hedgeRulesLoading
                    ? ButtonVariants.Default
                    : modeToBeChangedTo === ModeEnum.Enabled
                    ? ButtonVariants.Positive
                    : ButtonVariants.Negative
                }
                onClick={() => {
                  mixpanel.track(MixpanelEvent.ClickGlobalAutoHedgingActionButton, {
                    type: globalHedgeRule?.HedgeControlStatus,
                  });
                  previewView.open(modeToBeChangedTo);
                }}
                disabled={hedgeRulesLoading}
              >
                {hedgeRulesLoading ? (
                  <>
                    Loading hedger status... <Spinner size={8} />
                  </>
                ) : (
                  <>{modeToBeChangedTo === ModeEnum.Enabled ? 'Activate' : 'Disable'} Position Autohedging</>
                )}
              </Button>
            </Flex>
          </Flex>
          <SmallHedgePositionStatusBlotterTable status={StatusForIdx[selectedTabIndex]} />
        </Flex>
      </PreviewWrapperBox>
    </Popover>
  );
}

const StatusForIdx = {
  1: HedgeControlStatusEnum.Error,
  2: HedgeControlStatusEnum.Hedging,
};

const HedgingButton = ({
  hedgeRulesLoading,
  errorCount,
  hedgerEnabled,
  onClick,
}: {
  hedgeRulesLoading: boolean;
  errorCount: number;
  hedgerEnabled: boolean;
  onClick: () => void;
}) => {
  return (
    <Button variant={ButtonVariants.Default} ghost onClick={onClick} data-testid="hedging-button">
      <HStack fontSize="fontSizeSmall" fontWeight="bold" color="colorTextDefault" gap="spacingSmall">
        Autohedging
        {hedgeRulesLoading ? (
          <IndicatorBadge
            data-testid="hedging-button-loading"
            children={
              <>
                <Spinner size={8} />
                <Text ml="spacingTiny">Loading</Text>
              </>
            }
            variant={IndicatorBadgeVariants.Default}
          />
        ) : hedgerEnabled ? (
          <IndicatorBadge
            data-testid="hedging-button-enabled"
            children={
              <>
                <Icon icon={IconName.Check} />
                <Text ml="spacingTiny">Enabled</Text>
              </>
            }
            variant={IndicatorBadgeVariants.Positive}
          />
        ) : (
          <IndicatorBadge
            data-testid="hedging-button-disabled"
            children={
              <>
                <Icon icon={IconName.Clear} />
                <Text ml="spacingTiny">Disabled</Text>
              </>
            }
            variant={IndicatorBadgeVariants.Negative}
          />
        )}
        {errorCount > 0 && (
          <IndicatorBadge
            data-testid="hedging-button-error"
            children={
              <>
                <Icon icon={IconName.ClearCircle} />
                <Text ml="spacingTiny">{errorCount}</Text>
              </>
            }
            variant={IndicatorBadgeVariants.Negative}
          />
        )}
      </HStack>
    </Button>
  );
};
