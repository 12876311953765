import {
  Alert,
  AlertVariants,
  Button,
  FormControlSizes,
  HedgeCommandStatusEnum,
  HedgeControlStatusEnum,
  HelpIcon,
  HStack,
  NotificationVariants,
  Tooltip,
  type IHedgeRule,
  type ToastProps,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { useHedgeRuleCommandMutation } from '../../containers/Trading/Markets/PositionAutoHedgingRules/queries';
import type { IHedgePositionStatusRow } from '../../providers/HedgePositionStatusProvider';

export function PositionAutoHedgingStatus({
  hedgePositionStatus,
  hedgeRule,
  addToast,
  hasFormChanged,
}: {
  hedgePositionStatus: IHedgePositionStatusRow;
  hedgeRule: IHedgeRule;
  addToast: (toast: ToastProps) => void;
  hasFormChanged: boolean;
}) {
  const ruleDisabled = hedgePositionStatus.HedgeControlStatus === HedgeControlStatusEnum.Disabled;

  const sendHedgeCommandMutation = useHedgeRuleCommandMutation();

  const handleClickButton = useCallback(() => {
    sendHedgeCommandMutation
      .mutateAsync({
        Command: ruleDisabled ? 'Activate' : 'Kill',
        HedgeRuleID: hedgeRule.HedgeRuleID,
      })
      .then(res => {
        if (res.Status === HedgeCommandStatusEnum.Success) {
          addToast({
            text: `Successfully ${ruleDisabled ? 'enabled' : 'disabled'} rule.`,
            variant: NotificationVariants.Positive,
          });
        } else {
          addToast({
            text: `Failed to ${ruleDisabled ? 'enable' : 'disable'} rule.\n${res.Error}`,
            variant: NotificationVariants.Negative,
          });
        }
      })
      .catch(() => {
        addToast({
          text: `Failed to ${ruleDisabled ? 'enable' : 'disable'} rule.`,
          variant: NotificationVariants.Negative,
        });
      });
  }, [addToast, hedgeRule.HedgeRuleID, ruleDisabled, sendHedgeCommandMutation]);

  const disallowEnablingRule = hasFormChanged && ruleDisabled;

  return (
    <Alert
      variant={ruleDisabled ? AlertVariants.Negative : AlertVariants.Positive}
      dismissable={false}
      alignItems="center"
      p="spacingDefault"
    >
      Rule is currently {ruleDisabled ? 'disabled' : 'enabled'}.
      <HStack ml="auto" gap="spacingSmall">
        {disallowEnablingRule && (
          <Tooltip tooltip="Please save or discard your changes before enabling the rule.">
            <HelpIcon />
          </Tooltip>
        )}
        <Button
          disabled={disallowEnablingRule}
          data-testid="toggle-hedgerule"
          loading={sendHedgeCommandMutation.isPending}
          onClick={handleClickButton}
          size={FormControlSizes.Small}
        >
          {ruleDisabled ? 'Enable' : 'Disable'}
        </Button>
      </HStack>
    </Alert>
  );
}
