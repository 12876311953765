import { createSelector } from '@reduxjs/toolkit';
import { BaseField, type IHedgeRule } from '@talos/kyoko';
import { every, isEqual, isUndefined, pick } from 'lodash';
import type { EditIHedgeRuleForm, HedgeRulePayload, PositionAutoHedgingDrawerState } from './types';

/**
 * Select a JSON payload from the form state.
 */
export const selectFormValues = (form: EditIHedgeRuleForm): HedgeRulePayload => {
  return {
    Asset: form.Asset.value?.Symbol,
    TargetAsset: form.TargetAsset.value?.Symbol,
    TargetAccountID: form.TargetAccount.value?.SubaccountID,
    HedgeStrategy: form.HedgeStrategy.value?.Name,
    LongPositionLimit: form.LongPositionLimit.value,
    ShortPositionLimit: form.ShortPositionLimit.value,
    TargetPosition: form.TargetPosition.value,
    AccountID: form.AccountID.value?.SubaccountID,
    HedgeAggregation: form.HedgeAggregation.value?.Name,
  };
};

export const selectFormValuesValid = (state: HedgeRulePayload): state is Required<HedgeRulePayload> => {
  return every(Object.keys(state), key => !isUndefined(state[key]));
};

export const selectFormIsValid = createSelector(
  (state: PositionAutoHedgingDrawerState) => state.form,
  form => {
    return !Object.keys(form).some(key => {
      const maybeField = form[key];
      if (maybeField instanceof BaseField) {
        return maybeField.hasError || maybeField.hasWarning;
      }
      return false;
    });
  }
);

const FORM_KEYS = [
  'LongPositionLimit',
  'ShortPositionLimit',
  'TargetPosition',
  'Asset',
  'TargetAsset',
  'TargetAccount',
  'HedgeStrategy',
  'AccountID',
  'HedgeAggregation',
] satisfies (keyof EditIHedgeRuleForm)[];
type EnsureAllKeysUsed = Exclude<keyof EditIHedgeRuleForm, (typeof FORM_KEYS)[number]> extends never ? true : never;
// Ensure all keys are used in the form
const _allKeysUsedCheck: EnsureAllKeysUsed = true;

/**
 * Determine if the backend representation of the hedge rule being changed is out of sync
 * with the fields that initialized the form.
 */
export const selectIsHedgeRuleOutOfSync = ({
  hedgeRuleBeingChanged,
  latestHedgeRule,
}: {
  hedgeRuleBeingChanged: PositionAutoHedgingDrawerState['hedgeRuleBeingChanged'];
  latestHedgeRule: IHedgeRule | undefined;
}): boolean => {
  if (!hedgeRuleBeingChanged || !latestHedgeRule) {
    return false;
  }

  const form = pick(hedgeRuleBeingChanged, FORM_KEYS);
  const latestBackendRepresentation = pick(latestHedgeRule, FORM_KEYS);

  return !isEqual(form, latestBackendRepresentation) && hedgeRuleBeingChanged.Revision !== latestHedgeRule.Revision;
};

export const selectFormHasChanged = createSelector(
  (state: PositionAutoHedgingDrawerState) => state.form,
  (state: PositionAutoHedgingDrawerState) => state.hedgeRuleBeingChanged,
  (form, hedgeRuleBeingChanged) => {
    if (!hedgeRuleBeingChanged) {
      return true;
    }
    const formValues = selectFormValues(form);
    const isChanged = Object.keys(formValues).some(key => formValues[key] !== hedgeRuleBeingChanged[key]);
    return isChanged;
  }
);

/**
 * Frontend needs to figure out what symbol we can use to hedge the position.
 */
export const selectHedgeSymbol = createSelector(
  (state: PositionAutoHedgingDrawerState) => state.form.Asset.value,
  (state: PositionAutoHedgingDrawerState) => state.form.TargetAsset.value,
  (state: PositionAutoHedgingDrawerState) => state.referenceData.positionHedgerHomeCurrency,
  (state: PositionAutoHedgingDrawerState) => state.referenceData.spotTradingPairsByBaseCurrency,
  (assetField, targetAssetField, positionHedgerHomeCurrency, spotTradingPairsByBaseCurrency) => {
    if (!assetField || !targetAssetField) {
      return undefined;
    }

    if (assetField.Symbol !== targetAssetField.Symbol) {
      let candidateSecurity = spotTradingPairsByBaseCurrency
        .get(assetField.Symbol)
        ?.find(pair => pair.QuoteCurrency === targetAssetField.Symbol);
      if (candidateSecurity) {
        return candidateSecurity.Symbol;
      }
      candidateSecurity = spotTradingPairsByBaseCurrency
        .get(targetAssetField.Symbol)
        ?.find(pair => pair.QuoteCurrency === assetField.Symbol);
      if (candidateSecurity) {
        return candidateSecurity.Symbol;
      }

      return undefined;
    }

    // If Asset and TargetAsset are the same, we need to figure out the hedge symbol
    // by using the positionHedgerHomeCurrency
    let candidateSecurity = spotTradingPairsByBaseCurrency
      .get(assetField.Symbol)
      ?.find(pair => pair.QuoteCurrency === positionHedgerHomeCurrency);
    if (!candidateSecurity) {
      candidateSecurity = spotTradingPairsByBaseCurrency
        .get(positionHedgerHomeCurrency)
        ?.find(pair => pair.QuoteCurrency === assetField.Symbol);
    }
    if (candidateSecurity) {
      return candidateSecurity.Symbol;
    }
    return undefined;
  }
);
