import { AggregationType, DELETE, GET, PATCH, POST, request, useEndpointsContext } from '@talos/kyoko';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import type { Aggregation, AggregationMarket, AggregationWithAccounts } from 'types';

interface AggregationResponse {
  data: Aggregation[];
}

interface AggregationMarketResponse {
  data: AggregationMarket[];
}

export function useAggregations() {
  const { orgApiEndpoint } = useEndpointsContext();
  const endpoint = `${orgApiEndpoint}/aggregations`;
  const marketEndpoint = `${orgApiEndpoint}/aggregations/markets`;

  const listAggregations = useCallback(
    (aggregationType?: AggregationType) =>
      request<AggregationResponse>(
        GET,
        aggregationType == null ? endpoint : `${endpoint}?AggregationType=${aggregationType}`,
        null
      ),
    [endpoint]
  );

  const listAggregationsWithAccounts = useCallback(
    async (aggregationType?: AggregationType) => {
      return listAggregations(aggregationType).then(async response => {
        const { data } = response;
        const aggregationMap = new Map<string, AggregationWithAccounts>();
        for (const aggregation of data) {
          aggregationMap.set(aggregation.Name, {
            Accounts: new Map(),
            ...aggregation,
          });
        }
        return request<AggregationMarketResponse>(GET, marketEndpoint, null).then(marketResponse => {
          const markets = marketResponse.data;
          for (const market of markets) {
            if (aggregationMap.has(market.Aggregation)) {
              const aggregation = aggregationMap.get(market.Aggregation)!;
              aggregation.Accounts.set(market.MarketAccount || '', market);
            }
          }
          return Array.from(aggregationMap.values());
        });
      });
    },
    [marketEndpoint, listAggregations]
  );

  const queryClient = useQueryClient();
  const createAggregation = useCallback(
    (aggregation: Partial<Aggregation>) => {
      queryClient.invalidateQueries({ queryKey: AggregationsQueryKey, exact: true });
      return request<AggregationResponse>(POST, endpoint, aggregation);
    },
    [endpoint, queryClient]
  );

  const updateAggregation = useCallback(
    (aggregation: Partial<Aggregation>) => {
      queryClient.invalidateQueries({ queryKey: AggregationsQueryKey, exact: true });
      return request<AggregationResponse>(PATCH, endpoint, aggregation);
    },
    [endpoint, queryClient]
  );
  const deleteAggregation = useCallback(
    (name: string) => {
      queryClient.invalidateQueries({ queryKey: AggregationsQueryKey, exact: true });
      return request<AggregationResponse>(DELETE, `${endpoint}/${encodeURIComponent(name)}`, null);
    },
    [endpoint, queryClient]
  );

  const [aggregationsByName, setAggregationsByName] = useState<Map<string, Aggregation>>();
  useEffect(() => {
    if (aggregationsByName == null) {
      listAggregations().then(({ data }) => {
        const map = new Map<string, Aggregation>();
        for (const aggregation of data) {
          map.set(aggregation.Name, aggregation);
        }
        setAggregationsByName(map);
      });
    }
  }, [aggregationsByName, listAggregations]);

  return {
    listAggregations,
    listAggregationsWithAccounts,
    createAggregation,
    updateAggregation,
    deleteAggregation,
    aggregationsByName,
  };
}

const AggregationsQueryKey = ['aggregations'] as const;

export function useAggregationsQuery() {
  const { listAggregationsWithAccounts } = useAggregations();

  return useQuery({
    queryKey: AggregationsQueryKey,
    queryFn: () => listAggregationsWithAccounts(AggregationType.User),
    staleTime: 60_000,
    refetchOnMount: false,
  });
}
