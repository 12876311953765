import { invariant } from '@epic-web/invariant';
import type { Column, ColumnDef, ISubaccount, Paths, SizeColumnParams, Tree } from '@talos/kyoko';
import {
  AGGRID_AUTOCOLUMN_ID,
  COLUMN_GROUP_NATIVE_TYPE,
  extractColumns,
  type ColumnGroup,
  type ColumnOrColumnGroup,
} from '@talos/kyoko/src/components/BlotterTable/types';
import { useDefaultColumnsWithGroupings } from '@talos/kyoko/src/components/BlotterTable/useDefaultColumnsWithGroupings';
import { useRollupTreeRef } from 'hooks';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo } from 'react';
import {
  GREEK_DISPLAY_NAME_MAP,
  GREEK_ORDER,
  MONEYNESS_COLUMN_MAP,
  TENOR_COLUMN_MAP,
  UNDERLIER_GROUP_PREFIX,
  type Greeks,
  type MoneynessServerTypes,
  type PortfolioRiskBlotterGridData,
  type RiskAggMode,
  type TenorServerTypes,
} from '../../types/types';

type SplitField =
  | [unknown, unknown, 'Tenor', TenorServerTypes, RiskAggMode]
  | [unknown, unknown, 'Moneyness', MoneynessServerTypes, RiskAggMode];
export function splitPivotField(field: string): SplitField {
  const splitValue = field.split('.');
  invariant(splitValue.length === 5, 'Pivot columns fields must have 5 parts');
  return splitValue as SplitField;
}

/** Business logic - for pivots, aggregate DeltaExposure and Theta all the way up, everything else up to underlier only */
const fullAggregationGreeks: Greeks[] = ['DeltaExposure', 'Theta'];
// TODO: Add Generic typing to SizeColumnParams so this properly can tie to the grid data
/** Custom Aggregate checker to ensure that pivot aggregations only flow up to the underlier level */
const aggregatePivotIf: SizeColumnParams['aggregateIf'] = params => {
  // only aggregate if we are in a proper group node
  if (!params.rowNode?.group || params.rowNode.level < 0) {
    return false;
  }
  const aggField: string = params.rowNode?.groupData?.[AGGRID_AUTOCOLUMN_ID];
  if (typeof aggField !== 'string') {
    throw new Error('AggregateIf (pivot) column must have a field');
  }

  const colDef = params.colDef;
  invariant(colDef.field, 'AggregateIf (pivot) column must have a field');
  const fieldSplit = splitPivotField(colDef.field);
  const [_, greek, _pivotType, _bucket, _netGross] = fieldSplit;
  if (aggField.startsWith(UNDERLIER_GROUP_PREFIX)) {
    return true;
  }
  if (fullAggregationGreeks.includes(greek as Greeks)) {
    return true;
  }
  return false;
};

function buildPivotColumns(
  type: 'Tenor' | 'Moneyness',
  recordMap: typeof TENOR_COLUMN_MAP | typeof MONEYNESS_COLUMN_MAP
): ColumnGroup[] {
  return Object.entries(GREEK_ORDER).map(([greek, greekInfo]) => {
    return {
      groupId: `${type}_${greek}`,
      type: COLUMN_GROUP_NATIVE_TYPE,
      headerName: GREEK_DISPLAY_NAME_MAP[greek],
      // since pivot columns are dynamic, we don't want to show them in the column tool panel
      suppressColumnsToolPanel: true,
      children: ['Net', 'Gross'].flatMap(netGross =>
        Object.entries(recordMap).map(([serverType, displayName]) => {
          const col: Column = {
            field: `Pivot.${greek}.${type}.${serverType}.${netGross}`,
            description: `${netGross} ${greekInfo.tooltipBase} (${displayName} ${type})`,
            type: 'size',
            hide: true,
            aggregate: true,
            // same as the pivot column group, we don't want to show these in the column tool panel
            suppressColumnsToolPanel: true,
            params: {
              currencyField: 'Equivalent.Currency' satisfies Paths<PortfolioRiskBlotterGridData>,
              increment: '0.01',
              round: true,
              highlightNegative: true,
              aggregateIf: aggregatePivotIf,
              aggregateEmptyValues: true,
            } satisfies SizeColumnParams,
            title: displayName,
          };
          return col;
        })
      ),
    };
  });
}

const TENOR_COLUMNS: ColumnGroup[] = buildPivotColumns('Tenor', TENOR_COLUMN_MAP);
const MONEYNESS_COLUMNS: ColumnGroup[] = buildPivotColumns('Moneyness', MONEYNESS_COLUMN_MAP);

const visibleColumns: Array<keyof ReturnType<typeof usePortfolioRiskColumnDefs>['mapping'] | ColumnGroup> = [
  'pmsWarnings',
  'weight',
  'position',
  'positionHome',
  'price',
  ...TENOR_COLUMNS,
  ...MONEYNESS_COLUMNS,
];

export const usePortfolioRiskColumns = (): {
  defaultColumns: ColumnOrColumnGroup[];
  defaultColumnsFlat: Column[];
} => {
  const { homeCurrency } = useDisplaySettings();
  const rollupTreeRef = useRollupTreeRef();

  const { colDefsMap } = usePortfolioRiskColumnDefs(rollupTreeRef, homeCurrency);
  const defaultColumns = useDefaultColumnsWithGroupings(visibleColumns, colDefsMap);
  const flatDefultColumns = useMemo(() => {
    return extractColumns(defaultColumns);
  }, [defaultColumns]);
  return {
    defaultColumns,
    defaultColumnsFlat: flatDefultColumns,
  };
};

function usePortfolioRiskColumnDefs(rollupTreeRef: React.MutableRefObject<Tree<ISubaccount>>, homeCurrency: string) {
  return useMemo(() => {
    const mapping = {
      pmsWarnings: {
        id: 'warnings',
        field: 'PMSWarningColumnValue',
        type: 'pmsWarnings',
        titleIntlKey: 'warning',
        aggregate: true,
        pinned: 'left',
      },
      subAccount: {
        id: 'SubAccount',
        field: 'SubAccount',
        type: 'subAccountName',
        sortable: true,
        hide: true,
        params: {
          rollupTree: rollupTreeRef,
        },
      },
      assetField: {
        id: 'Asset',
        field: 'Asset',
        type: 'asset',
        pinned: 'left',
        sortable: true,
        width: 150,
        hide: true,
        title: 'Asset',
        params: {
          assetTypeField: 'AssetType' satisfies Paths<PortfolioRiskBlotterGridData>,
          colorful: true,
        },
      },
      weight: {
        id: 'Weight',
        field: 'Equivalent.Weight',
        title: 'Weight (%)',
        width: 80,
        type: 'percent',
        sortable: true,
        description: 'Weight of the position in the portfolio.',
      },
      price: {
        field: 'MarkPrice',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'MarkPriceCurrency' satisfies Paths<PortfolioRiskBlotterGridData>,
        },
        description: 'Most recent mark price for the instrument provided by the venue.',
      },

      position: {
        id: 'Position',
        field: 'Position',
        title: 'Position Qty',
        type: 'size',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'Asset' satisfies Paths<PortfolioRiskBlotterGridData>,
          trimTrailingZeroes: true,
        },
        sortable: true,
        description: 'Position normalized in asset currency.',
      },
      positionHome: {
        type: 'size',
        field: 'Equivalent.Position',
        title: `Position (${homeCurrency})`,
        params: {
          currencyField: 'Equivalent.Currency' satisfies Paths<PortfolioRiskBlotterGridData>,
          highlightNegative: true,
        },
        sortable: true,
        aggregate: true,
        description: 'Position normalized in home currency, by using mark and underlying prices.',
      },
      riskValuationTime: {
        field: 'RiskValuationTime',
        title: 'Risk Valuation Time',
        type: 'date',
      },
    } as const satisfies Record<string, ColumnDef<PortfolioRiskBlotterGridData>>;

    const colDefsMap = Object.entries(mapping).reduce((result, [key, value]) => {
      result.set(key, value);
      return result;
    }, new Map<string, Column>());

    return {
      mapping,
      colDefsMap,
    };
  }, [homeCurrency, rollupTreeRef]);
}
