import { createContext, useContext } from 'react';

const CONTEXT_GUIDE_IDS = ['auto-hedge-position-rule'] as const;

export type ContextGuideID = (typeof CONTEXT_GUIDE_IDS)[number];

export const DefaultContextGuideConfig = {
  contextGuide: {} as Record<ContextGuideID, boolean>,
};

export const DefaultContextGuideMethods = {
  setGuide: (guideID: ContextGuideID, value: boolean) => ({}),
  getGuide: (guideID: ContextGuideID) => false,
};

export type ContextGuideContextProps = typeof DefaultContextGuideConfig & typeof DefaultContextGuideMethods;

export const DefaultContextGuideContext = { ...DefaultContextGuideConfig, ...DefaultContextGuideMethods };
export const ContextGuideContext = createContext(DefaultContextGuideContext);
ContextGuideContext.displayName = 'ContextGuideContext';
export const useContextGuide = () => useContext(ContextGuideContext);
