import { compact, uniq } from 'lodash';
import { useEffect, useState } from 'react';

import { useUserContext } from '@talos/kyoko';
import { useOrders } from 'providers/OrdersProvider';

import { useTradingSettings } from 'providers/AppConfigProvider';

export function useGroups() {
  const { enableGroups } = useTradingSettings();
  const { historicalOrders } = useOrders();
  const { orgApiEndpoint } = useUserContext();
  const [groupOptions, setGroupOptions] = useState<string[]>([]);
  useEffect(() => {
    if (orgApiEndpoint && enableGroups) {
      historicalOrders(orgApiEndpoint).then(orders => {
        setGroupOptions(uniq(compact(orders.data.map(order => order.Group))));
      });
    }
  }, [orgApiEndpoint, historicalOrders, enableGroups]);
  return groupOptions;
}
