import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  Field,
  MultiSelectorField,
  NumericField,
  OrderFormSides,
  QuoteStatusEnum,
  SelectorField,
  Unit,
  calculatePrecision,
  type Customer,
  type CustomerBalance,
  type CustomerQuote,
  type MarketAccount,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';
import type { WritableDraft } from 'immer/dist/types/types-external';
import type { AppState } from 'providers/AppStateProvider/types';
import { getDefaultMarketAccountForCustomer, getQuantityIncrement, setGlobalSymbol } from '../Common';
import { initialRefDataState } from '../OMSReferenceDataSlice';
import { numberIsPositive } from '../commonFieldRules';
import type { OMSReferenceDataState } from '../types';
import { saleQuantityIsAccountedFor } from './FieldRules';
import type { SalesRFQDependencies, SalesRFQInitPayload, SalesRFQState } from './types';

export const getInitialState = (): SalesRFQState => ({
  referenceData: initialRefDataState,
  dependencies: {
    availableRFQMarketAccounts: [],
  },
  form: {
    customerField: new SelectorField({ idProperty: 'Name' }),
    customerAccountField: new SelectorField({ idProperty: 'Name' }),
    symbolField: new SelectorField({ idProperty: 'Symbol' }),
    sideField: new Field({ value: OrderFormSides.Twoway }),
    customerQuantityField: new NumericField({ name: 'Quantity' }),
    rfqCurrencyField: new Field(),
    bidSpreadField: new NumericField({ unit: Unit.Bps }),
    offerSpreadField: new NumericField({ unit: Unit.Bps }),
    bidPriceField: new NumericField({ displayTrailingZeroes: true }),
    offerPriceField: new NumericField({ displayTrailingZeroes: true }),
    useDefaultAggregationField: new Field({ value: 'true' }),
    rfqMarketsField: new MultiSelectorField(),
  },
  isOpen: false,
  isLoading: false,
  isCustomerPriceEditable: false,
  currentQuote: undefined,
});

const resetEconomics = (state: WritableDraft<SalesRFQState>) => {
  state.isCustomerPriceEditable = false;
  state.isLoading = false;
  state.form.bidSpreadField = new NumericField({ unit: Unit.Bps });
  state.form.offerSpreadField = new NumericField({ unit: Unit.Bps });
  state.form.bidPriceField = new NumericField({ displayTrailingZeroes: true });
  state.form.offerPriceField = new NumericField({ displayTrailingZeroes: true });
};

const disableInputFields = (state: WritableDraft<SalesRFQState>, isDisabled: boolean) => {
  state.form.sideField = state.form.sideField.setDisabled(isDisabled);
  state.form.customerField = state.form.customerField.setDisabled(isDisabled);
  state.form.customerAccountField = state.form.customerAccountField.setDisabled(isDisabled);
  state.form.symbolField = state.form.symbolField.setDisabled(isDisabled);
  state.form.customerQuantityField = state.form.customerQuantityField.setDisabled(isDisabled);
  state.form.rfqMarketsField = state.form.rfqMarketsField.setDisabled(isDisabled);
};

const validate = (state: WritableDraft<SalesRFQState>) => {
  state.form.customerQuantityField = state.form.customerQuantityField.validate(
    [numberIsPositive, saleQuantityIsAccountedFor],
    state
  );
};

const handleSymbolChange = (state: WritableDraft<SalesRFQState>, symbol?: string) => {
  const security = state.form.symbolField.availableItems.find(s => s.Symbol === symbol);
  if (!security) {
    return;
  }

  state.form.symbolField = state.form.symbolField.updateValue(security);

  const { BaseCurrency, QuoteCurrency } = security || {};
  if (state.form.rfqCurrencyField.value !== BaseCurrency && state.form.rfqCurrencyField.value !== QuoteCurrency) {
    state.form.rfqCurrencyField = state.form.rfqCurrencyField.updateValue(BaseCurrency, true);
  }
  state.form.customerQuantityField = state.form.customerQuantityField
    .updateValue('', true)
    .updateScale(getQuantityIncrement(security, state.form.rfqCurrencyField.value));
};

export const salesRFQSlice = createSlice({
  name: 'salesRFQ',
  initialState: getInitialState(),
  reducers: {
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      state.referenceData = action.payload;
    },
    onMount: (state, action: PayloadAction<SalesRFQInitPayload>) => {
      state.initialPayload = action.payload;

      const { customers, customerMarketAccounts } = state.referenceData;
      state.form.customerField = state.form.customerField.updateAvailableItems(customers);

      const initialCustomer = state.form.customerField.availableItems.find(
        c => c.CounterpartyID === action.payload.initialCustomerID
      );
      if (!state.form.customerField.value && initialCustomer) {
        state.form.customerField = state.form.customerField.updateValue(initialCustomer, true);
      }

      if (!state.form.customerAccountField.value) {
        state.form.customerAccountField = getDefaultMarketAccountForCustomer(
          state.form.customerAccountField,
          customerMarketAccounts,
          initialCustomer
        );
      }
    },
    setDependencies: (state, action: PayloadAction<SalesRFQDependencies>) => {
      state.dependencies.customerBalances = action.payload.customerBalances;

      state.form.rfqMarketsField = state.form.rfqMarketsField.updateAvailableItems(
        action.payload.availableRFQMarketAccounts
      );
    },
    setAvailableCustomerSecurities: (state, action: PayloadAction<Security[]>) => {
      state.form.symbolField = state.form.symbolField.updateAvailableItems(action.payload);
      if (action.payload?.length === 1) {
        handleSymbolChange(state, action.payload[0].Symbol);
      }

      handleSymbolChange(state, state.form.symbolField.value?.Symbol);
    },
    setSide: (state, action: PayloadAction<OrderFormSides>) => {
      state.form.sideField = state.form.sideField.updateValue(action.payload);
      validate(state);
    },
    setCustomer: (state, { payload: customer }: PayloadAction<Customer>) => {
      state.form.customerField = state.form.customerField.updateValue(customer);
      state.form.customerAccountField = getDefaultMarketAccountForCustomer(
        state.form.customerAccountField,
        state.referenceData.customerMarketAccounts,
        customer
      );
      validate(state);
    },
    setCustomerAccount: (state, action: PayloadAction<MarketAccount>) => {
      state.form.customerAccountField = state.form.customerAccountField.updateValue(action.payload);
      validate(state);
    },
    setSymbol: (state, action: PayloadAction<string | undefined>) => {
      handleSymbolChange(state, action.payload);
    },
    setCustomerQuantity: (state, action: PayloadAction<string>) => {
      state.form.customerQuantityField = state.form.customerQuantityField.updateValue(action.payload);
      validate(state);
    },
    setRFQCurrency: (state, action: PayloadAction<string>) => {
      state.form.rfqCurrencyField = state.form.rfqCurrencyField.updateValue(action.payload);
      state.form.customerQuantityField = state.form.customerQuantityField
        .updateValue('', true)
        .updateScale(getQuantityIncrement(state.form.symbolField.value, action.payload));
    },
    setSpreadBid: (state, action: PayloadAction<string>) => {
      state.form.bidSpreadField = state.form.bidSpreadField.updateValue(action.payload);
    },
    setSpreadOffer: (state, action: PayloadAction<string>) => {
      state.form.offerSpreadField = state.form.offerSpreadField.updateValue(action.payload);
    },
    setCustomerBidPrice: (state, action: PayloadAction<string>) => {
      // Usually if user input exceeds decimal places we simply show an error; but for this use case we need to reject
      // and we can achieve that by simply reverting to previous value if new user input causes error in the field
      const previousValue = state.form.bidPriceField.value;
      state.form.bidPriceField = state.form.bidPriceField.updateValue(action.payload);
      if (state.form.bidPriceField.hasError) {
        state.form.bidPriceField = state.form.bidPriceField.updateValue(previousValue);
      }
    },
    setCustomerOfferPrice: (state, action: PayloadAction<string>) => {
      const previousValue = state.form.offerPriceField.value;
      state.form.offerPriceField = state.form.offerPriceField.updateValue(action.payload);
      if (state.form.offerPriceField.hasError) {
        state.form.offerPriceField = state.form.offerPriceField.updateValue(previousValue);
      }
    },
    setCurrentQuote: (state, { payload: quote }: PayloadAction<CustomerQuote>) => {
      state.currentQuote = quote;
      state.isLoading = false;

      // reset input fields & economics if rejected or cancelled
      if (quote.QuoteStatus === QuoteStatusEnum.Rejected || quote.QuoteStatus === QuoteStatusEnum.Canceled) {
        resetEconomics(state);
        disableInputFields(state, false);
      } else {
        disableInputFields(state, true);
        // setting initial bid/offer spreads and prices
        if (quote.EffectiveBidSpread && (!state.form.bidSpreadField.isTouched || state.isCustomerPriceEditable)) {
          state.form.bidSpreadField = state.form.bidSpreadField.updateValue(quote.EffectiveBidSpread || '', true);
        }
        if (quote.EffectiveOfferSpread && (!state.form.offerSpreadField.isTouched || state.isCustomerPriceEditable)) {
          state.form.offerSpreadField = state.form.offerSpreadField.updateValue(quote.EffectiveOfferSpread || '', true);
        }
        if (quote.BidPx && (!state.form.bidSpreadField.isTouched || !state.isCustomerPriceEditable)) {
          state.form.bidPriceField = state.form.bidPriceField
            .updateValue(quote.BidPx, true)
            .updateScale(calculatePrecision(quote.BidPx));
        }
        if (quote.OfferPx && (!state.form.offerPriceField.isTouched || !state.isCustomerPriceEditable)) {
          state.form.offerPriceField = state.form.offerPriceField
            .updateValue(quote.OfferPx, true)
            .updateScale(calculatePrecision(quote.OfferPx));
        }
      }

      if (quote.QuoteStatus === QuoteStatusEnum.PendingFill || quote.QuoteStatus === QuoteStatusEnum.PendingNew) {
        state.isLoading = true;
      }
    },
    toggleLock: state => {
      state.isCustomerPriceEditable = !state.isCustomerPriceEditable;
    },
    setUseDefaultAggregation: (state, action: PayloadAction<'true' | 'false'>) => {
      state.form.useDefaultAggregationField = state.form.useDefaultAggregationField.updateValue(action.payload);
      if (action.payload === 'true') {
        state.form.rfqMarketsField = state.form.rfqMarketsField.updateValue([]);
      }
    },
    setRFQMarkets: (state, action: PayloadAction<string[]>) => {
      state.form.rfqMarketsField = state.form.rfqMarketsField.updateValue(action.payload);
    },
    setQuoteRequested: state => {
      state.isLoading = true;
    },
    resetState: state => {
      const prevSymbol = state.form.symbolField;
      const prevRFQCurrency = state.form.rfqCurrencyField;
      const newState = getInitialState();
      newState.form.symbolField = prevSymbol.setTouched(false).setDisabled(false);
      newState.form.rfqCurrencyField = prevRFQCurrency.setTouched(false);
      newState.referenceData = state.referenceData;
      return newState;
    },
  },
  // https://redux-toolkit.js.org/api/createSlice#extrareducers
  // extraReducers allows own state update in response to action generated from other slices
  // in other words, we can listen to actions from another slice (reference data) to update this slice
  // the use case for now is when the top level OMS settings is updated, we can compare the new values (payload)
  // against the current values to determine what has changed and react appropriately
  extraReducers: builder => {
    builder.addCase(setGlobalSymbol, (state, action) => {
      handleSymbolChange(state, action.payload);
    });
  },
});

export const {
  setReferenceData,
  onMount,
  setDependencies,
  setAvailableCustomerSecurities,
  setSide,
  setCustomer,
  setCustomerAccount,
  setSymbol,
  setCustomerQuantity,
  setRFQCurrency,
  setSpreadBid,
  setSpreadOffer,
  setCustomerBidPrice,
  setCustomerOfferPrice,

  setCurrentQuote,
  toggleLock,
  setQuoteRequested,
  resetState,
  setUseDefaultAggregation,
  setRFQMarkets,
} = salesRFQSlice.actions;

// DERIVED state below
export const selectCanSendRfq = createSelector(
  (state: AppState) => state.salesRFQ.isLoading,
  (state: AppState) => state.salesRFQ.currentQuote?.QuoteStatus,
  (state: AppState) => state.salesRFQ.form,
  (isLoading, quoteStatus, form) => {
    const isOpen = quoteStatus === QuoteStatusEnum.Open;
    return (
      !isOpen &&
      !isLoading &&
      !form.customerField.hasInvalidValue &&
      !form.customerAccountField.hasInvalidValue &&
      !form.symbolField.hasInvalidValue &&
      !form.customerQuantityField.hasInvalidValue
    );
  }
);

export const selectCustomerProfit = createSelector(
  (state: AppState) => state.salesRFQ.form.symbolField.value?.BaseCurrency,
  (state: AppState) => state.salesRFQ.form.rfqCurrencyField.value,
  (state: AppState) => state.salesRFQ.form.customerQuantityField.value,
  (state: AppState) => state.salesRFQ.currentQuote,
  (baseCurrency, rfqCurrency, quantity, quote) => {
    let customerBidProfit = '';
    let customerOfferProfit = '';
    if (quote) {
      if (rfqCurrency === baseCurrency) {
        customerBidProfit = Big(quote.ExpectedHedgeBidPx || '0')
          .times(quantity || '1')
          .minus(quote.BidAmt || '0')
          .toFixed();
        customerOfferProfit = Big(quote.ExpectedHedgeOfferPx || '0')
          .times(quantity || '1')
          .minus(quote.OfferAmt || '0')
          .times(-1)
          .toFixed();
      } else {
        customerBidProfit = Big(quote.ExpectedHedgeBidPx || '0')
          .minus(quote.BidPx || '0')
          .times(quote.BidAmt || '1')
          .toFixed();
        customerOfferProfit = Big(quote.ExpectedHedgeOfferPx || '0')
          .minus(quote.OfferPx || '0')
          .times(quote.OfferAmt || '1')
          .times(-1)
          .toFixed();
      }
    }
    return { customerBidProfit, customerOfferProfit };
  }
);

export const selectCustomerBalance = createSelector(
  (state: AppState) => state.salesRFQ.form.customerField.value,
  (state: AppState) => state.salesRFQ.form.customerAccountField.value,
  (state: AppState) => state.salesRFQ.form.symbolField.value,
  (state: AppState) => state.salesRFQ.dependencies.customerBalances,
  (customer?: Customer, account?: MarketAccount, security?: Security, balances?: CustomerBalance[]) => {
    return (balances || []).filter(
      b =>
        b.Counterparty === customer?.Name &&
        // For 0 balances Back-end is sending down empty MarketAccount strings
        (b.MarketAccount === account?.Name || b.MarketAccount === '') &&
        (b.Currency === security?.BaseCurrency || b.Currency === security?.QuoteCurrency)
    );
  }
);
