import { Button, ButtonGroup, ButtonVariants, FormControlSizes, OrderFormSides, type SideEnum } from '@talos/kyoko';

export interface HeaderSideSelectorProps {
  allowTwoWay?: boolean;
  side: string | undefined;
  onChange: (side: OrderFormSides | SideEnum) => void;
  disabled?: boolean;
  size?: FormControlSizes;
}

export const HeaderSideSelector = ({
  allowTwoWay,
  side,
  onChange,
  disabled,
  size = FormControlSizes.Default,
}: HeaderSideSelectorProps) => {
  return (
    <ButtonGroup size={size}>
      <Button
        variant={side === OrderFormSides.Buy ? ButtonVariants.Positive : ButtonVariants.Default}
        onClick={() => onChange(OrderFormSides.Buy)}
        disabled={disabled}
        data-testid="buy-button"
      >
        Buy
      </Button>
      {allowTwoWay && (
        <Button
          variant={side === OrderFormSides.Twoway || side == null ? ButtonVariants.Primary : ButtonVariants.Default}
          onClick={() => onChange(OrderFormSides.Twoway)}
          disabled={disabled}
          data-testid="two-way-button"
        >
          2 way
        </Button>
      )}
      <Button
        variant={side === OrderFormSides.Sell ? ButtonVariants.Negative : ButtonVariants.Default}
        onClick={() => onChange(OrderFormSides.Sell)}
        disabled={disabled}
        data-testid="sell-button"
      >
        Sell
      </Button>
    </ButtonGroup>
  );
};
