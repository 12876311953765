import {
  Box,
  CurrencyIcon,
  Divider,
  Flex,
  HStack,
  HedgeControlStatusEnum,
  Icon,
  InlineFormattedNumber,
  ModeEnum,
  POSITION_SUB_ACCOUNT,
  PositionThresholdMeter,
  Text,
  iconByHedgePositionStatus,
  iconColorByHedgePositionStatus,
  toBigWithDefault,
  useCurrencyConversionRateValue,
  useObservableValue,
  useSubscription,
  type IHedgeRule,
  type MapTo,
  type Position,
} from '@talos/kyoko';
import Big, { type BigSource } from 'big.js';
import { useMemo } from 'react';
import { map } from 'rxjs';
import styled from 'styled-components';
import type { IHedgePositionStatusRow } from '../../providers/HedgePositionStatusProvider';
import type { EditIHedgeRuleForm } from './types';

/**
 * Card to display the current position and status of a hedge rule. Optionally leverages the status to render a meter.
 */
export function PositionAutoHedgingCard({
  form,
  hedgeRule,
  hedgePositionStatus,
  mode,
}: {
  form: EditIHedgeRuleForm;
  hedgePositionStatus: IHedgePositionStatusRow | undefined;
  hedgeRule: IHedgeRule | undefined;
  mode: ModeEnum;
}) {
  const { position: convertedPosition, source } = useCurrentPosition({
    account: form.AccountID.value?.Name,
    asset: form.Asset.value?.Symbol,
    targetAsset: form.TargetAsset.value?.Symbol,
    hedgePositionStatus,
  });

  const shortThreshold = form.ShortPositionLimit.value;
  const longThreshold = form.LongPositionLimit.value;
  const targetPosition = form.TargetPosition.value;

  const updated = useMemo(
    () =>
      ({
        LongPositionLimit: !toBigWithDefault(longThreshold, 0).eq(toBigWithDefault(hedgeRule?.LongPositionLimit, 1)),
        ShortPositionLimit: !toBigWithDefault(shortThreshold, 0).eq(toBigWithDefault(hedgeRule?.ShortPositionLimit, 1)),
        TargetPosition: !toBigWithDefault(targetPosition, 0).eq(toBigWithDefault(hedgeRule?.TargetPosition, 1)),
        AccountID: !(hedgeRule?.AccountID === form.AccountID.value?.SubaccountID),
        Asset: !(hedgeRule?.Asset === form.Asset.value?.Symbol),
        TargetAsset: !(hedgeRule?.TargetAsset === form.TargetAsset.value?.Symbol),
      } satisfies Partial<MapTo<IHedgeRule, boolean>>),
    [
      form.AccountID.value?.SubaccountID,
      form.Asset.value?.Symbol,
      form.TargetAsset.value?.Symbol,
      hedgeRule?.AccountID,
      hedgeRule?.Asset,
      hedgeRule?.LongPositionLimit,
      hedgeRule?.ShortPositionLimit,
      hedgeRule?.TargetAsset,
      hedgeRule?.TargetPosition,
      longThreshold,
      shortThreshold,
      targetPosition,
    ]
  );

  const hedgeControlStatus =
    hedgePositionStatus?.HedgeControlStatus ??
    (mode === ModeEnum.Disabled ? HedgeControlStatusEnum.Disabled : HedgeControlStatusEnum.Waiting);

  const positionThresholdMeterProps = useMemo(() => {
    return {
      CurrentPosition: convertedPosition?.toString(),
      HedgeControlStatus: hedgeControlStatus,
      LongPositionLimit: longThreshold,
      ShortPositionLimit: shortThreshold,
      TargetAsset: form.TargetAsset.value?.Symbol,
      TargetPosition: targetPosition,
    };
  }, [
    convertedPosition,
    form.TargetAsset.value?.Symbol,
    hedgeControlStatus,
    longThreshold,
    shortThreshold,
    targetPosition,
  ]);

  return (
    <CardWrapper fontSize="fontSizeSmall">
      <HStack justifyContent="space-between">
        <Text>Monitored Rollup</Text>
        <Text fontStyle={updated.AccountID ? 'italic' : undefined} color="colorTextImportant">
          {form.AccountID.value?.DisplayName ?? '-'}
        </Text>
      </HStack>
      <HStack justifyContent="space-between">
        <Text>Monitored Asset</Text>
        <HStack color="colorTextImportant">
          {!form.Asset.value ? (
            '-'
          ) : (
            <>
              <CurrencyIcon currency={form.Asset.value.Symbol} size={12} />
              <Box ml="spacingSmall">
                <Text fontStyle={updated.Asset ? 'italic' : undefined}>{form.Asset.value.Symbol}</Text>
              </Box>
            </>
          )}
        </HStack>
      </HStack>

      <>
        <HStack justifyContent="space-between" data-testid="hedge-control-status">
          <Text>Status</Text>
          <Flex gap="spacingSmall" color="colorTextImportant">
            <Icon
              size={12}
              icon={iconByHedgePositionStatus[hedgeControlStatus]}
              color={iconColorByHedgePositionStatus[hedgeControlStatus]}
            />
            <Text>{hedgeControlStatus}</Text>
          </Flex>
        </HStack>
        <Divider />
        <HStack justifyContent="space-between" data-testid="current-position">
          <Text>Current Position</Text>
          {convertedPosition === undefined ? (
            '-'
          ) : (
            <InlineFormattedNumber
              round
              estimate={source === 'conversion'}
              number={convertedPosition}
              currency={form.TargetAsset.value?.Symbol}
            />
          )}
        </HStack>

        <PositionThresholdMeter value={positionThresholdMeterProps} updated={updated} />
      </>
    </CardWrapper>
  );
}

const useCurrentPosition = ({
  account,
  asset,
  hedgePositionStatus,
  targetAsset,
}: {
  asset: string | undefined;
  account: string | undefined;
  targetAsset: string | undefined;
  hedgePositionStatus: IHedgePositionStatusRow | undefined;
}): {
  position: BigSource | undefined;
  source: 'hedgePositionStatus' | 'conversion' | 'none';
} => {
  const shouldRequestPosition = !!(
    asset &&
    account &&
    // only request for a new position if we are sure the current hedgePositionStatus does not carry the correct information
    (targetAsset !== hedgePositionStatus?.TargetAsset || !hedgePositionStatus)
  );

  const request = useMemo(
    () =>
      shouldRequestPosition
        ? {
            name: POSITION_SUB_ACCOUNT,
            tag: 'PositionAutoHedgingCard',
            Currencies: [asset],
            SubAccount: account,
          }
        : null,
    [shouldRequestPosition, asset, account]
  );

  const { data: positionData } = useSubscription<Position>(request);

  const position = useObservableValue(() => positionData.pipe(map(json => json.data.at(0))), [positionData]);

  const currencyConversionRate = useCurrencyConversionRateValue(
    shouldRequestPosition ? asset : undefined,
    shouldRequestPosition ? targetAsset : undefined
  );

  if (hedgePositionStatus?.CurrentPosition && !shouldRequestPosition) {
    // We have the position, and it is matching the target asset, meaning we show the hedge position untouched
    return { position: hedgePositionStatus.CurrentPosition, source: 'hedgePositionStatus' };
  }

  if (shouldRequestPosition && position?.Amount && currencyConversionRate?.Rate) {
    // We have the position and the conversion rate, meaning we can calculate the converted position
    return { position: Big(position.Amount).times(currencyConversionRate.Rate), source: 'conversion' };
  }
  // No hedgePositionStatus nor possible conversion, return undefined
  return { position: undefined, source: 'none' };
};

const CardWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacingDefault}px;
  background-color: ${({ theme }) => theme.backgroundCard};
  border-radius: ${({ theme }) => theme.borderRadiusCard}px;
  padding: ${({ theme }) => theme.spacingDefault}px;
`;
