import { DefaultFavoritesConfig, DefaultTabsStateConfig, DefaultWatchlistSettingsConfig } from '@talos/kyoko';
import { DefaultBlotterSettingsConfig } from '@talos/kyoko/src/providers/BlotterSettingContext';
import { OrderStrategiesEnum } from '../tokens/orderStrategy';
import { DefaultBlottersConfig } from './BlottersContext';
import { DefaultContextGuideConfig } from './ContextGuideContext';
import { DefaultDisplaySettingsConfig } from './DisplaySettingsProvider';
import { DefaultMarketTabsConfig } from './MarketTabs.types';
import type { IOrderPreset } from './OrderPresetsContext';
import { DefaultPortfolioSettingsConfig } from './PortfolioSettingContext';
import { DefaultSoundSettingsConfig } from './SoundContext';
import { DefaultTradingSettingsConfig } from './TradingSettingsContext.types';

export const DEFAULT_APP_CONFIG = {
  recentSymbols: {},
  lastStrategyUsed: OrderStrategiesEnum.Limit,
  lastOrderFormUsed: '',
  blotters2: {},
  orderPresetsList: [] as IOrderPreset[],
  ...DefaultBlottersConfig,
  ...DefaultTabsStateConfig,
  ...DefaultDisplaySettingsConfig,
  ...DefaultMarketTabsConfig,
  ...DefaultTradingSettingsConfig,
  ...DefaultWatchlistSettingsConfig,
  ...DefaultSoundSettingsConfig,
  ...DefaultFavoritesConfig,
  ...DefaultPortfolioSettingsConfig,
  ...DefaultBlotterSettingsConfig,
  ...DefaultContextGuideConfig,
};

export type AppConfigState = typeof DEFAULT_APP_CONFIG;
