import {
  Box,
  Button,
  ButtonVariants,
  DEFAULT_CUSTOMER_MARKET_ACCOUNT,
  Dialog,
  Divider,
  FormControlSizes,
  FormGroup,
  GET,
  Grid,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  NotificationVariants,
  SearchSelect,
  Text,
  Tooltip,
  abbreviateId,
  bpsToPercent,
  request,
  useConstant,
  useCurrenciesContext,
  useGlobalToasts,
  useMarketAccountsContext,
  useSecuritiesContext,
  useSecurity,
  useUserContext,
  type IOMSTradeCapture,
  type SideEnum,
  type SubAccount,
} from '@talos/kyoko';
import { useCallback, useEffect, useMemo } from 'react';

import { HeaderSideSelector } from 'components/OMS/HeaderSideSelector';
import { AmountWarningComponent, useManualTradeEntry } from 'hooks/useManualTradeEntry';
import { identity, isNil } from 'lodash';

import { MarketAccountStatusEnum } from '@talos/kyoko';
import { useCustomers, useCustomersByName, useCustomersContext } from 'hooks/useCustomer';
import type { CustomerTradeUpdate } from 'providers/CustomersContext.types';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { v1 as uuid } from 'uuid';
import type { BookAndModifyTradeDialogProps, CustomerTradeForm } from './types';

function getSubAccountLabel(subAccount: SubAccount) {
  return subAccount.DisplayName;
}

export const BookAndModifyTradeDialog = ({ form, updateForm, ...props }: BookAndModifyTradeDialogProps) => {
  const customerService = useCustomersContext();
  const { QuoteCurrency, BaseCurrency } = useSecurity(form?.symbol) ?? {};
  const { add: addToast } = useGlobalToasts();
  const { allSubAccountBooks, subAccountsByName } = useSubAccounts();
  const { currenciesList } = useCurrenciesContext();
  const { orgApiEndpoint } = useUserContext();

  const isEdit = !!form?.tradeID;

  // Request trade details when tradeID changes, since trade websocket msg lacks SalesComission and SubAccount.
  // This map holds the (singular) trade detail for the form.tradeID (if it exist).
  const tradeCaptureMap = useConstant(new Map<string, IOMSTradeCapture | null>());
  const isRequestingTradeDetails = isEdit && !isNil(form.tradeID) && !tradeCaptureMap.has(form.tradeID);

  useEffect(() => {
    if (!props.isOpen) {
      // when closing the dialog, clear the trade details cache.
      tradeCaptureMap.clear();
      return;
    }

    if (form?.tradeID) {
      request<{ data: [IOMSTradeCapture] }>(GET, `${orgApiEndpoint}/trades?TradeID=${form.tradeID}`)
        .then(res => {
          const trade = res.data.at(0);
          if (trade) {
            if (form.tradeID) {
              tradeCaptureMap.set(form.tradeID, trade);
            }
            updateForm({
              salesCommission: trade.SalesCommission,
              riskSubAccount: trade.SubAccount,
            });
          }
        })
        .catch(() => {
          // We did not get a trade, but we have decided to edit it.
          // Lets fail gracefully by populating map with null, signalling that we tried.
          if (form.tradeID) {
            tradeCaptureMap.set(form.tradeID, null);
          }
        });
    }
  }, [form.tradeID, updateForm, orgApiEndpoint, tradeCaptureMap, props.isOpen]);

  const formHasErrors = useCallback(
    (form: CustomerTradeForm) => {
      const { counterparty, symbol, side, quantity, price, amount, clTradeID, marketAccount } = form;

      return (
        !counterparty || !symbol || !side || !quantity || !price || !amount || !marketAccount || (!isEdit && !clTradeID)
      );
    },
    [isEdit]
  );

  const createCustomerTrade = useCallback(
    async (customerTrade: CustomerTradeForm) => {
      const {
        counterparty,
        symbol,
        side,
        quantity,
        price,
        amount,
        salesCommission,
        clTradeID,
        tradeID,
        marketAccount,
        comments,
        riskSubAccount,
        fee,
        feeCurrency,
      } = customerTrade;
      if (formHasErrors(customerTrade) || !BaseCurrency || !QuoteCurrency) {
        return;
      }
      const request: CustomerTradeUpdate = {
        Counterparty: counterparty,
        Symbol: symbol,
        Side: side,
        Currency: BaseCurrency,
        Quantity: quantity,
        Price: price,
        Amount: amount,
        AmountCurrency: QuoteCurrency,
        SalesCommission: bpsToPercent(salesCommission),
        ClTradeID: clTradeID,
        TradeID: tradeID,
        MarketAccount: marketAccount,
        Comments: comments,
        RiskSubAccount: riskSubAccount,
        Fee: fee,
      };
      if (request.Fee) {
        request.FeeCurrency = feeCurrency;
      }

      const promise = isEdit
        ? customerService.updateCustomerTrade(request)
        : customerService.createCustomerTrade(request);

      return promise
        .then(res => {
          addToast({
            text: `Customer Trade ${abbreviateId(res.data[0].TradeID)} ${isEdit ? 'updated' : 'created'}.`,
            variant: NotificationVariants.Positive,
          });
          // After a change, trade data might've changed, so remove from cache.
          if (form.tradeID) {
            tradeCaptureMap.delete(form.tradeID);
          }
          updateForm({
            amount: '',
            salesCommission: '',
            quantity: '',
            price: '',
            clTradeID: '',
            fee: '',
            tradeID: undefined,
          });
        })
        .catch((e: ErrorEvent) => {
          addToast({
            text: e?.toString() || `Could not ${isEdit ? 'modify' : 'add'} customer trade.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [
      BaseCurrency,
      QuoteCurrency,
      addToast,
      customerService,
      formHasErrors,
      updateForm,
      isEdit,
      tradeCaptureMap,
      form.tradeID,
    ]
  );

  const { onRecompute, amountWarningText, feeWarningText } = useManualTradeEntry({
    quantity: form.quantity,
    amount: form.amount,
    price: form.price,
    symbol: form.symbol,
    fee: form.fee,
    feeBPS: form.feeBPS,
    feeCurrency: form.feeCurrency,
    updateForm,
  });

  const handleClickRecompute = useCallback(
    (field?: 'quantity' | 'amount' | 'price' | 'fee' | 'feeBPS') => onRecompute(field),
    [onRecompute]
  );

  const handleOnGenerateID = useCallback(() => {
    updateForm({ clTradeID: uuid() });
  }, [updateForm]);

  const customersByName = useCustomersByName();
  const getCustomerLabel = useCallback(
    customerName => customersByName?.get(customerName)?.DisplayName ?? customerName,
    [customersByName]
  );

  const customers = useCustomers();
  const customerOptions = useMemo(() => {
    return customers ? customers.map(c => c.Name) : [];
  }, [customers]);

  const { securitiesList } = useSecuritiesContext();
  const securityOptions = useMemo(() => {
    return securitiesList ? securitiesList.map(security => security.Symbol) : [];
  }, [securitiesList]);

  const { marketAccountsByCounterparty } = useMarketAccountsContext();
  const customerMarketAccounts = useMemo(() => {
    if (!form.counterparty) {
      return [];
    }

    return (
      marketAccountsByCounterparty
        .get(form.counterparty)
        // DEAL-3733: Only show active accounts
        ?.filter(ma => ma.Status === MarketAccountStatusEnum.Active)
        .map(ma => ma.Name) || []
    );
  }, [form.counterparty, marketAccountsByCounterparty]);

  const feeCurrencyOptions = useMemo(() => {
    return currenciesList.map(c => c.Symbol);
  }, [currenciesList]);

  // Default to QuoteCurrency if Symbol is set.
  useEffect(() => {
    if (form.symbol && QuoteCurrency && !form.feeCurrency) {
      updateForm({ feeCurrency: QuoteCurrency });
    }
  }, [QuoteCurrency, form.feeCurrency, form.symbol, updateForm]);

  const handleCounterpartyUpdate = useCallback(
    (newCounterparty?: string) => {
      if (!newCounterparty) {
        updateForm({ marketAccount: undefined, counterparty: undefined });
        return;
      }

      // whenever the newCounterparty changes, we set it to the default of the new counterparty
      const newMktAcc = marketAccountsByCounterparty
        .get(newCounterparty)
        ?.find(ma => ma.SourceAccountID === DEFAULT_CUSTOMER_MARKET_ACCOUNT)?.Name;
      updateForm({ marketAccount: newMktAcc, counterparty: newCounterparty });
    },
    [marketAccountsByCounterparty, updateForm]
  );

  const handleSubAccountOnChange = useCallback(
    (selection?: SubAccount) => {
      updateForm({ riskSubAccount: selection?.Name });
    },
    [updateForm]
  );

  const canComputeFeeFromBPS = [BaseCurrency, QuoteCurrency].includes(form.feeCurrency);

  // Trigger FeeBps to compute on modification with fees
  useEffect(() => {
    if (form.fee && canComputeFeeFromBPS && !form.feeBPS && isEdit) {
      onRecompute();
    }
  }, [form.fee, form.feeBPS, canComputeFeeFromBPS, isEdit, onRecompute]);

  return (
    <Dialog
      {...props}
      onConfirm={() => createCustomerTrade(form)}
      confirmLabel={`${isEdit ? 'Modify' : 'Create'} Customer Trade`}
      cancelLabel={`${isEdit ? 'Close' : 'Cancel'}`}
      width={550}
      confirmDisabled={formHasErrors(form) || isRequestingTradeDetails}
      autoFocusFirstElement={false}
      title={`${isEdit ? 'Modify' : 'Book'} Manual Customer Trade`}
    >
      <Box textAlign="left">
        <HStack w="100%" mb="spacingComfortable" gap="spacingSmall">
          <FormGroup label="Customer" w="100%" mb="0">
            <SearchSelect
              selection={form.counterparty}
              options={customerOptions}
              getLabel={getCustomerLabel}
              onChange={handleCounterpartyUpdate}
              placeholder="Customer"
              disabled={isEdit}
              data-testid="customer"
            />
          </FormGroup>
          <FormGroup label="Customer Account" w="100%" mb="0">
            <SearchSelect
              selection={form.marketAccount}
              options={customerMarketAccounts}
              onChange={marketAccount => updateForm({ marketAccount })}
              getLabel={identity}
              placeholder="Customer Account"
              disabled={isEdit || (customerMarketAccounts.length === 1 && form.marketAccount != null)}
              data-testid="customer-account"
            />
          </FormGroup>
        </HStack>
        <FormGroup mb="spacingComfortable" label="Symbol">
          <SearchSelect
            selection={form.symbol}
            options={securityOptions}
            onChange={symbol => updateForm({ symbol })}
            getLabel={identity}
            placeholder="Symbol"
            disabled={isEdit}
            data-testid="symbol"
          />
        </FormGroup>
        <FormGroup mb="spacingComfortable">
          <HeaderSideSelector
            allowTwoWay={false}
            side={form.side}
            onChange={side => updateForm({ side: side as SideEnum })}
            disabled={isEdit}
            data-testid="side"
          />
        </FormGroup>
        <Box mb="spacingMedium">
          <Divider />
        </Box>
        <HStack mb="spacingComfortable" gap="spacingSmall">
          <FormGroup label="Quantity" mb={0}>
            <Input
              inputType="number"
              value={form.quantity}
              placeholder="Quantity"
              onChange={e => updateForm({ quantity: e.target.value })}
              disabled={!form.symbol}
              onBlur={() => onRecompute()}
              suffix={
                <HStack gap="spacingSmall" mr="spacingTiny">
                  {BaseCurrency}
                  {amountWarningText != null && (
                    <Tooltip tooltip="Re-compute Quantity">
                      <IconButton
                        size={FormControlSizes.Small}
                        variant={ButtonVariants.Default}
                        onClick={() => handleClickRecompute('quantity')}
                        icon={IconName.Refresh}
                      />
                    </Tooltip>
                  )}
                </HStack>
              }
              data-testid="quantity"
            />
          </FormGroup>
          <FormGroup label="Price" mb={0}>
            <Input
              inputType="number"
              value={form.price}
              placeholder="Price"
              onChange={e => updateForm({ price: e.target.value })}
              disabled={!form.symbol}
              onBlur={() => onRecompute()}
              suffix={
                <HStack gap="spacingSmall" mr="spacingTiny">
                  {QuoteCurrency}
                  {amountWarningText != null && (
                    <Tooltip tooltip="Re-compute Price">
                      <IconButton
                        size={FormControlSizes.Small}
                        variant={ButtonVariants.Default}
                        onClick={() => handleClickRecompute('price')}
                        icon={IconName.Refresh}
                      />
                    </Tooltip>
                  )}
                </HStack>
              }
              data-testid="price"
            />
          </FormGroup>
          <FormGroup label="Amount" mb={0}>
            <Input
              inputType="number"
              value={form.amount}
              placeholder="Amount"
              onChange={e => updateForm({ amount: e.target.value })}
              disabled={!form.symbol}
              onBlur={() => onRecompute()}
              suffix={
                <HStack gap="spacingSmall" mr="spacingTiny">
                  {QuoteCurrency}
                  {amountWarningText != null && (
                    <Tooltip tooltip="Re-compute Amount">
                      <IconButton
                        size={FormControlSizes.Small}
                        variant={ButtonVariants.Default}
                        onClick={() => handleClickRecompute('amount')}
                        icon={IconName.Refresh}
                      />
                    </Tooltip>
                  )}
                </HStack>
              }
              data-testid="amount"
            />
          </FormGroup>
        </HStack>
        <Grid columns="1fr 1fr 1fr" rows={1} gap="spacingDefault" w="100%" mb="spacingMedium">
          <FormGroup label="Commission" mb={0}>
            <Input
              type="number"
              value={form.salesCommission}
              placeholder="Commission"
              onChange={e => updateForm({ salesCommission: e.target.value })}
              disabled={!form.symbol || isRequestingTradeDetails}
              suffix="BPS"
              data-testid="commission"
            />
          </FormGroup>
          <FormGroup label="Fee" mb={0}>
            <Input
              type="number"
              value={form.fee}
              placeholder="Fee"
              onBlur={() => onRecompute()}
              onChange={e => updateForm({ fee: e.target.value })}
              disabled={!form.symbol}
              data-testid="fee"
              suffix={
                <HStack gap="spacingSmall" mr="spacingTiny">
                  <SearchSelect
                    selection={form.feeCurrency}
                    options={feeCurrencyOptions}
                    onChange={feeCurrency => updateForm({ feeCurrency })}
                    getLabel={identity}
                    placeholder="Currency"
                    size={FormControlSizes.Small}
                    data-testid="fee-currency"
                  />
                  {feeWarningText != null && (
                    <Tooltip tooltip="Re-compute Fee">
                      <IconButton
                        size={FormControlSizes.Small}
                        variant={ButtonVariants.Default}
                        onClick={() => handleClickRecompute('fee')}
                        icon={IconName.Refresh}
                        data-testid="fee-refresh"
                      />
                    </Tooltip>
                  )}
                </HStack>
              }
            />
          </FormGroup>
          <FormGroup label="Fee BPS" mb={0}>
            <Input
              type="number"
              value={canComputeFeeFromBPS ? form.feeBPS : 'N/A'}
              placeholder="Fee"
              onBlur={() => onRecompute()}
              onChange={e => updateForm({ feeBPS: e.target.value })}
              disabled={!canComputeFeeFromBPS}
              data-testid="fee-bps"
              suffix={
                <HStack gap="spacingSmall" mr="spacingTiny">
                  BPS
                  {feeWarningText != null && (
                    <Tooltip tooltip="Re-compute Fee BPS">
                      <IconButton
                        size={FormControlSizes.Small}
                        variant={ButtonVariants.Default}
                        onClick={() => handleClickRecompute('feeBPS')}
                        icon={IconName.Refresh}
                        data-testid="fee-bps-refresh"
                      />
                    </Tooltip>
                  )}
                </HStack>
              }
            />
          </FormGroup>
        </Grid>
        {amountWarningText && (
          <FormGroup mb="spacingComfortable">
            <AmountWarningComponent text={amountWarningText} data-testid="amount-warning" />
          </FormGroup>
        )}
        {feeWarningText && (
          <FormGroup mb="spacingComfortable">
            <AmountWarningComponent text={feeWarningText} data-testid="fee-warning" />
          </FormGroup>
        )}
        <FormGroup mb="spacingComfortable" label="External ID">
          <Input
            inputType="text"
            value={form.clTradeID}
            placeholder="External ID"
            onChange={e => updateForm({ clTradeID: e.target.value })}
            disabled={isEdit}
            suffix={
              <Button
                size={FormControlSizes.Small}
                variant={ButtonVariants.Default}
                onClick={handleOnGenerateID}
                disabled={isEdit}
                ghost
              >
                Generate
              </Button>
            }
            data-testid="external-id"
          />
        </FormGroup>
        <FormGroup mb="spacingComfortable" label="Internal Comments">
          <Input
            inputType="text"
            value={form.comments}
            placeholder="Optional"
            onChange={e => updateForm({ comments: e.target.value })}
            data-testid="comments"
          />
        </FormGroup>
        {(allSubAccountBooks?.length ?? 0) > 0 && (
          <FormGroup
            mb="spacingComfortable"
            label={
              <HStack gap="spacingSmall">
                <Text>Risk SubAccount</Text>
                <Tooltip
                  tooltip="(Optional) This is the subaccount for managing the risk associated with this trade"
                  usePortal={false}
                >
                  <Icon icon={IconName.QuestionMarkCircle} />
                </Tooltip>
              </HStack>
            }
          >
            <SearchSelect
              disabled={isRequestingTradeDetails}
              id="manual-trade-subaccount"
              getLabel={getSubAccountLabel}
              options={allSubAccountBooks!}
              selection={form.riskSubAccount ? subAccountsByName?.get(form.riskSubAccount) : undefined}
              onChange={handleSubAccountOnChange}
              data-testid="risk-sub-account"
            />
          </FormGroup>
        )}
      </Box>
    </Dialog>
  );
};
