import { useDefaultColumns, type Column, type ColumnDef, type Paths } from '@talos/kyoko';
import { useRollupTreeRef } from 'hooks';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo } from 'react';
import { getPnLColumn } from '../utils';
import type { PortfolioPerformanceBlotterGridItem } from './types';

export const usePortfolioPerformanceColumns = (): {
  defaultColumns: Column[];
} => {
  const { homeCurrency } = useDisplaySettings();
  const { colDefsMap } = usePortfolioPerformanceColumnDefs(homeCurrency);
  const defaultColumns = useDefaultColumns(visibleColumns, colDefsMap);
  return {
    defaultColumns,
  };
};

export function usePortfolioPerformanceColumnDefs(homeCurrency: string) {
  const rollupTreeRef = useRollupTreeRef();
  return useMemo(() => {
    const mapping = {
      pmsWarnings: {
        id: 'warnings',
        field: 'PMSWarningColumnValue',
        type: 'pmsWarnings',
        titleIntlKey: 'warning',
        aggregate: true,
        pinned: 'left',
      },
      subAccount: {
        id: 'SubAccount',
        field: 'SubAccount',
        type: 'subAccountName',
        sortable: true,
        params: {
          rollupTree: rollupTreeRef,
        },
      },
      assetField: {
        id: 'Asset',
        field: 'Asset',
        type: 'asset',
        pinned: 'left',
        sortable: true,
        width: 150,
        title: 'Asset',
        params: {
          assetTypeField: 'AssetType',
          colorful: true,
        },
      },
      weight: {
        id: 'Weight',
        field: 'Equivalent.Weight',
        title: 'Weight (%)',
        width: 80,
        type: 'percent',
        sortable: true,
        description: 'Weight of the position in the portfolio.',
      },
      price: {
        field: 'MarkPrice',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'MarkPriceCurrency' satisfies Paths<PortfolioPerformanceBlotterGridItem>,
        },
        description: 'Most recent mark price for the instrument provided by the venue.',
      },
      position: {
        id: 'Position',
        field: 'Position',
        title: 'Position Qty',
        type: 'size',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'Asset' satisfies Paths<PortfolioPerformanceBlotterGridItem>,
          trimTrailingZeroes: true,
        },
        sortable: true,
        description: 'Position normalized in asset currency.',
      },
      positionHome: {
        type: 'size',
        field: 'Equivalent.Position',
        title: `Position (${homeCurrency})`,
        params: {
          currencyField: 'Equivalent.Currency' satisfies Paths<PortfolioPerformanceBlotterGridItem>,
          highlightNegative: true,
        },
        sortable: true,
        aggregate: true,
        description: 'Position normalized in home currency, by using mark and underlying prices.',
      },
      avgCost: {
        title: 'Avg Cost',
        field: 'PositionPnl.AvgCost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'PositionPnl.AvgCostCurrency' satisfies Paths<PortfolioPerformanceBlotterGridItem>,
        },
        description: 'Average cost for the instrument provided by the venue.',
      },
      fees: {
        field: 'PositionPnl.CumFees',
        type: 'size',
        title: 'Fees',
        params: {
          currencyField: 'PositionPnl.PnLCurrency' satisfies Paths<PortfolioPerformanceBlotterGridItem>,
        },
        aggregate: true,
        description: `Cumulative trading fees paid (or rebates received) (in settlement currency for derivatives, home currency otherwise)`,
      },
      realizedPnL: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'Realized PnL',
        field: 'PositionPnl.RealizedPnL',
        currencyType: 'quote',
        homeCurrency,
        description: 'Realized P&L',
      }),
      unrealizedPnL: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'Unrealized PnL',
        field: 'PositionPnl.UnrealizedPnL',
        currencyType: 'quote',
        homeCurrency,
        description: 'Unrealized P&L',
      }),
      totalPnL: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'Total PnL',
        field: 'PositionPnl.TotalPnL',
        currencyType: 'quote',
        homeCurrency,
        description: 'Total P&L',
      }),
      avgCostHome: {
        title: `Avg Cost (${homeCurrency})`,
        field: 'PositionPnl.Equivalent.AvgCost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'Equivalent.Currency' satisfies Paths<PortfolioPerformanceBlotterGridItem>,
        },
        description: 'Average cost for the instrument provided by the venue.',
      },
      feesHome: {
        field: `PositionPnl.Equivalent.CumFees`,
        type: 'size',
        title: `Fees (${homeCurrency})`,
        params: {
          currencyField: 'Equivalent.Currency' satisfies Paths<PortfolioPerformanceBlotterGridItem>,
        },
        aggregate: true,
        description: `Cumulative trading fees paid (in home currency).`,
      },
      realizedPnLHome: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'Realized PnL',
        field: 'PositionPnl.RealizedPnL',
        currencyType: 'home',
        homeCurrency,
        description: `Realized P&L`,
      }),
      unrealizedPnLHome: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'Unrealized PnL',
        field: 'PositionPnl.UnrealizedPnL',
        currencyType: 'home',
        homeCurrency,
        description: `Unrealized P&L`,
      }),
      totalPnLHome: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'Total PnL',
        field: 'PositionPnl.TotalPnL',
        currencyType: 'home',
        homeCurrency,
        description: `Total P&L`,
      }),
      pnl24H: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: '24H PnL',
        field: 'PositionPnl.PnLLookbacks.H24.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 24 hours to the current price mark.',
      }),
      pnl7D: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: '7D PnL',
        field: 'PositionPnl.PnLLookbacks.D7.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 7 days to the current price mark.',
      }),
      pnl30D: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: '30D PnL',
        field: 'PositionPnl.PnLLookbacks.D30.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 30 days to the current price mark.',
      }),
      pnl365D: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: '365D PnL',
        field: 'PositionPnl.PnLLookbacks.D365.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 365 days to the current price mark.',
      }),
      pnlToday: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'Intraday PnL',
        field: 'PositionPnl.PnLLookbacks.Today.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: "P&L on asset from start of day to the current price mark (e.g. today's gain/loss).",
      }),
      pnlWTD: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'WTD PnL',
        field: 'PositionPnl.PnLLookbacks.WeekToDate.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the week to date to the current price mark.',
      }),
      pnlMTD: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'MTD PnL',
        field: 'PositionPnl.PnLLookbacks.MonthToDate.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the month to date to the current price mark.',
      }),
      pnlYTD: getPnLColumn<PortfolioPerformanceBlotterGridItem>({
        title: 'YTD PnL',
        field: 'PositionPnl.PnLLookbacks.YearToDate.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the year to date to the current price mark.',
      }),
      underlyingPrice: {
        field: 'PositionPnl.UnderlyingPrice',
        title: 'Underlying Price',
        type: 'price',
        width: 130,
        params: {
          quoteCurrencyField:
            'PositionPnl.UnderlyingQuoteCurrency' satisfies Paths<PortfolioPerformanceBlotterGridItem>,
        },
        description: "Most recent fair price for the instrument's underlying provided by the venue.",
      },
      positionDelta: {
        field: 'PositionPnl.Delta',
        title: 'Delta',
        type: 'delta',
        aggregate: true,
        params: {
          securityField: 'Asset',
        },
        description:
          "Delta measures the rate of change of an instrument's price with respect to changes in its underlying asset's price. ",
      },
      positionEquivalentDelta: {
        field: 'PositionPnl.Equivalent.Delta',
        type: 'size',
        title: `Delta (${homeCurrency})`,
        params: {
          currencyField: 'Equivalent.Currency' satisfies Paths<PortfolioPerformanceBlotterGridItem>,
          highlightNegative: true,
        },
        description: `Delta (${homeCurrency}) measures the rate of change of an instrument's price with respect to changes in its underlying asset's price, in home currency terms. `,
      },
    } as const satisfies Record<string, ColumnDef<PortfolioPerformanceBlotterGridItem>>;

    const colDefsMap = Object.entries(mapping).reduce((result, [key, value]) => {
      result.set(key, value);
      return result;
    }, new Map<string, Column>());

    return {
      mapping,
      colDefsMap,
    };
  }, [homeCurrency, rollupTreeRef]);
}

const visibleColumns: Array<keyof ReturnType<typeof usePortfolioPerformanceColumnDefs>['mapping']> = [
  'pmsWarnings',
  'weight',
  'price',
  'position',
  'positionHome',
  'realizedPnL',
  'unrealizedPnL',
  'totalPnL',
  'avgCostHome',
  'feesHome',
  'realizedPnLHome',
  'unrealizedPnLHome',
  'totalPnLHome',
  'pnl24H',
  'pnl7D',
  'pnl30D',
  'pnl365D',
  'pnlToday',
  'pnlWTD',
  'pnlMTD',
  'pnlYTD',
  'underlyingPrice',
  'positionDelta',
  'positionEquivalentDelta',
];
